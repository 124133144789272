//
// ----------------------------------------
// MIXINS
//
@mixin cf {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  margin: 0 auto;
  text-align: left;
  position: relative;
  height: 208px;
  &::after {
    letter-spacing: 0.1em;
    content: "...";
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0 0 1px 35px;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(rgba(255, 255, 255, 0)),
      to(white),
      color-stop(60%, white)
    );
    // background: -moz-linear-gradient(to right, rgba(255, 255, 255, 0), white 60%, white);
    // background: -o-linear-gradient(to right, rgba(255, 255, 255, 0), white 60%, white);
    // background: -ms-linear-gradient(to right, rgba(255, 255, 255, 0), white 60%, white);
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white 60%, white);
  }
}
// ----------------------------------------
// Transitions

@mixin transition($props: all, $speed: 0.1s, $easing: linear) {
  transition: $props $speed $easing;
}

// How to use?
// If you want to add transition for color just:
//
// > @include transition(color);

// ----------------------------------------
// Image sources

@function brand($name) {
  $imagePath: "../img/brandmarks/";
  @return url(#{$imagePath}#{$name});
}

@function icon($name) {
  $imagePath: "../img/icons/";
  @return url(#{$imagePath}#{$name});
}

@function pic($name) {
  $imagePath: "../img/pics/";
  @return url(#{$imagePath}#{$name});
}

// How to use?
// We keep all our images by default in "img" folder. Inside it we group images in brandmarks, icons and pics folders.
// These mixins are for quicker access for the images. We don't need to write:
//
// > background-image: url('../img/icons/twitter.svg');
//
// We can do it simpler:
//
// > background-image: icon('twitter.svg');
