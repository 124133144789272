.slider-wrapper {
  margin: 15px -15px -15px;
  visibility: hidden;
}

.home-slider {
  width: 100%;
  padding: 0;
  overflow: hidden;

  ul {
    padding: 0;
  }

  img {
    height: 100%;
    width: auto;
  }

  .slick-arrow slick-next::before {
  }

  .slick-dots {
    position: absolute;
    bottom: 15px;
    left: 50%;
    z-index: 1;
    // !important is needed to override an inline style slick adds to the slider
    display: flex !important;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    transform: translate(-50%, 0);
    gap: 10px;
  }

  .slick-dots li {
    margin: 0;
    width: auto;
    height: 10px;
  }

  .slick-dots button {
    display: block;
    width: 10px;
    height: 10px;
    padding: 0;
    font-size: 0;
    line-height: 0;
    border: none;
    background-color: white;
    opacity: 0.7;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .slick-dots-black button {
    background-color: black;
  }

  .slick-dots button::before {
    content: none;
  }

  .slick-dots .slick-active button {
    width: 30px;
    opacity: 1;
  }

  &.slick-slider {
    margin: 0px 0px;
  }
}
