.sc_faq-block {
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.sc_faq-block-header {
  padding: 10px;
}

.sc_faq-block-content {
  border-top: 1px solid #eee;
  padding: 1px 15px 1px 15px;
}

.sc_faq-question {
  font-weight: bold;
  font-size: em(14);
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.sc_faq-answer {
  margin-bottom: 1em;
  font-size: em(14);

  a {
    color: $sc_primary-color;
    text-decoration: underline;
  }
}

.sc_faq-block-open {
  display: block;
}

.sc_faq-block-closed {
  display: none;
}

.sc_faq-contact {
  list-style: none;
}
