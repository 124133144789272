a.no-decoration {
  text-decoration: none;
}

.blog {
  img {
    display: block;
    margin: 1em auto;
    max-width: 100%;
    height: auto;
  }

  > * {
    max-width: 610px;
  }

  ol,
  ul,
  p {
    font-size: 16px;
    hyphens: auto;

    a {
      color: #34b78f;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  h2 {
    font-size: 30px;
    font-weight: 700;
    line-height: 37px;
  }

  h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
  }
}
