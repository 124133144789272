.totp {
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 4px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  width: 100%;

  .btn {
    margin: 30px auto;
  }
}

.totp__setup {
  text-align: center;

  h3 {
    text-align: center;
  }

  input {
    padding: 10px 7px;
    font-size: 20px;
    border-radius: 3px;
    border: 1px solid #ddd;
    width: 50%;
    margin-top: 15px;
  }

  .error {
    text-align: left;
  }
}
