.sc_team-list {
  min-height: 400px;
}

.sc_team-item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  font-size: em(14);
  position: relative;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #fafafa;
  }
}

.sc_team-item-top {
  box-shadow: 0 4px 4px -4px rgba(#000, 0.2);
  z-index: 1;
}

.sc_team-item-color {
  border-radius: 50%;
  margin-right: 15px;
  height: 21px;
  width: 21px;
  flex: none;
}

.sc_team-item-plus {
  margin-right: 15px;
  height: 20px;
  width: 20px;
  line-height: 20px;
}

.sc_team-item-name {
  margin-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sc_team-item-label {
  border: 1px solid $sc_light;
  color: $sc_light;
  font-size: 0.857em;
  padding: 2px 5px;
  line-height: 1em;
  border-radius: 9px;
  font-weight: 400;
  flex: none;
  margin-right: 10px;
}

.sc_team-item-locker {
  opacity: 0.4;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
