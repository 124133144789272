.slider {
  padding: 0 40px;
  margin-top: 40px;
  position: relative;

  @include media("<sm") {
    display: none;
  }

  .slider__track-wrapper {
    overflow: hidden;
    padding: 0 !important;
  }

  .slider__track {
    flex-wrap: nowrap !important;
    transition: all 0.5s ease-in-out;
    margin: 0 !important;
  }

  .slider__item {
    padding-bottom: 0;

    p {
      line-height: 1.2em;
      font-size: 14px;
      padding: 10px;
      margin: 0;
    }
  }
}

.slider-nav {
  transition: all 0.2s ease-in-out;
  color: $color-black;
  display: block;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  box-shadow: $shadow;
  cursor: pointer;
  border: 1px solid #ddd;
  outline: 0;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #fff;

  &.slider-nav--prev {
    left: 0;
    padding: 7px 10px;
  }

  &.slider-nav--next {
    right: 0;
    padding: 7px 13px;
  }

  &:disabled {
    opacity: 0.1 !important;
    cursor: default;
  }
}

.slider-dots {
  text-align: center;
  padding: 10px 0 0;

  button {
    display: inline-block;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: #efefef;
    transition: all 0.2s ease-in-out;
    margin: 0 5px;
    outline: 0;
  }
}
