.sc_header {
  font-size: em(14);
  height: 3rem;
  flex-direction: row;
  padding: 6px 15px;
  background-color: #fff;
  position: relative;
  width: 100%;
  z-index: 12;
  box-shadow: 0px 6px 20px 0px rgba(112, 144, 176, 0.16);
}

.sc_header-left {
  flex: 1;
  min-width: 0;
}

.sc_header-team {
  min-width: 0;
  padding-right: 20px;
  white-space: nowrap;

  span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .sc_header-team-count {
    font-size: 0.857em;
  }
}

.sc_header-team-color {
  border-radius: 50%;
  margin-right: 10px;
  height: 20px;
  width: 20px;
  min-width: 20px;
}

.sc_header-spectator {
  i {
    font-size: 1.5em;
  }
}
