.password-container {
  padding-top: 10px;
  margin-bottom: 20px;
}

.form-group__char-left {
  position: absolute;
  right: 0;
  bottom: 100%;
  font-size: 10px;
}

.pwd-validation {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4);
}

.psw-validation__body {
  padding: 30px 20px 8px;
}

.pwd-validation__header {
  background: #adbdbd;
  padding: 14px 10px 11px;
  border-radius: 3px 3px 0 0;
  position: relative;
  transition: all 0.3s ease-in-out;

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 5px;
    border-color: transparent transparent #adbdbd;
    position: absolute;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);
    transition: all 0.3s ease-in-out;
  }

  h4 {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }
}

.pwd-validation__list-title {
  font-weight: 700;
  margin: 0 0 22px;
}

.pwd-validation__list {
  padding: 0;
  list-style: none;
  margin-bottom: 22px;

  li {
    padding-left: 22px;
    position: relative;
    margin-bottom: 5px;

    &:before {
      content: "";
      background: $mf-color-text-default;
      display: block;
      height: 5px;
      width: 5px;
      border-radius: 50%;
      left: 7px;
      top: 7px;
      position: absolute;
    }

    ul {
      padding: 0;
      display: inline-block;
      margin-left: 4px;

      li {
        display: inline-block;
        padding: 0;
        font-weight: 700;
        margin-right: 4px;

        &:before {
          display: none;
        }
      }
    }
  }

  .warning {
    display: block;
    font-size: 10px;
  }
}

.pwd-validation--valid {
  .pwd-validation__header {
    background: #6eca77;

    &:before {
      border-color: transparent transparent #6eca77;
    }
  }
}

.pwd-validation--invalid {
  .pwd-validation__header {
    background: #ff664b;

    &:before {
      border-color: transparent transparent #ff664b;
    }
  }
}

.pwd-validation--invalid,
.pwd-validation--valid {
  .pwd-validation__list {
    li {
      &:before {
        height: 16px;
        width: 16px;
        border-radius: 0;
        background: url("../../images/invalid.svg") no-repeat 50%;
        left: 0;
        top: 2px;
      }

      &.valid {
        &:before {
          background-image: url("../../images/valid.svg");
        }
      }
    }
  }
}

.psw-validation__error {
  color: #ff664b;
  margin-bottom: 28px;
}
