.tiles-grid-single {
  position: relative;
  display: block;
  flex: 0 1 100%;
  width: 100%;
  padding-top: 100%; // keep aspect ratio 1:1
  overflow: hidden;
  border-radius: 8px;
  transition: all 0.15s ease-in-out;

  h2 {
    margin: 0 0 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    &.small {
      font-size: 25px;
      line-height: 1.2em;
      @include media("<lg") {
        font-size: 15px;
      }
      @include media("<sm") {
        font-size: 10px;
      }
    }
  }
  p {
    margin: 0 0 20px;
  }
  &:hover:not(.no-hover-effect) .tiles-grid-image {
    transform: scale(1.15);
  }
  &.scaled-down {
    transform: scale(0);
    &:hover {
      transition-delay: 0.3s;
    }
  }
  .company-overlay {
    opacity: 0.9;
    position: absolute;
    left: -1px;
    top: -1px;
    right: -1px;
    bottom: -1px;
    text-align: center;
    padding-top: 15%;
    background: linear-gradient(to bottom, #fff 20%, rgba(0, 0, 0, 0.3) 100%);
    img {
      display: inline;
      max-width: 80%;
    }
  }
  .company-overlay--single-card {
    padding-top: 5%;

    img {
      width: 30%;
    }
  }
}

.tiles-grid-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.tiles-grid-content {
  font-size: 17px;
  position: absolute;
  top: 0;
  padding: 30px 19px;
  color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.3));

  h2 {
    font-size: 32px;
    line-height: 40px;

    @include media("<md") {
      font-size: 20px;
      line-height: 26px;
    }
  }

  @include media("<md") {
    margin-right: 4px;
    padding: 14px 9px;
    overflow: hidden;
    h2 ~ * {
      display: none;
    }
  }
}

.tiles-grid-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: scale(1.04);
  transition: all 0.15s ease-in-out;
}
