.product-detail__product-price-box-card {
  padding: 24px 24px 32px 24px;
  background: white;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  border: 1px solid #d4d4d4;
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;

  .price-row {
    justify-content: space-between;
    align-items: center;
    display: flex;

    .price-free {
      width: 100%;
    }

    span {
      color: black;
      font-size: 16px;
      letter-spacing: 0.48px;
      font-weight: 400;
      line-height: 24px;

      &:last-child {
        color: black;
        font-size: 48px;
        font-weight: 700;
        line-height: 60px;
        text-align: right;
      }
    }
  }

  .remaining-balance-text {
    margin-top: 0px;
    color: #808080;
    font-size: 14px;
    line-height: 20px;
    word-wrap: break-word;
    align-self: flex-end;
    text-align: right;
  }

  .button-row {
    gap: 8px;
    display: flex;
  }

  &.sticky-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    bottom: 0px;
    height: auto;
    border-radius: 0px;
    padding: 24px;
    border: 0;
    border-top: 1px solid #d4d4d4;
    z-index: 2000;

    &:has(:only-child) {
      justify-content: center;
    }

    button {
      max-width: 50%;
    }

    @include media(">md") {
      display: none; /* Hide mobile sticky-footer product-price-box on desktop */
    }

    .icon:before {
      display: none;
    }

    .price-row {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      span {
        &:last-child {
          font-size: 26px;
          line-height: 26px;
          text-align: left;
        }
      }
    }
  }
}

.booking-product-price-box-button {
  width: 100%;
  height: auto;
  padding: 10px 18px;
  height: 48px;
  background: #34b78f;
  border-radius: 9999px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  word-wrap: break-word;
  color: white;
  cursor: pointer;

  @include media(">md") {
    font-size: 18px;
  }

  &.white {
    border: 1px solid var(--Component-Colors-Buttons-Secondary-button-secondary-border, #c7c7c7);
    background: var(--Component-Colors-Buttons-Secondary-button-secondary-bg, #fff);
    color: var(--Component-Colors-Buttons-Secondary-button-secondary-fg, #4d4d4d);
  }

  &:disabled {
    cursor: not-allowed;
    border: 1px solid var(--Colors-Border-border-disabled, #d6d6d6);
    background: var(--Colors-Background-bg-disabled, #f5f5f5);
    color: var(--Colors-Foreground-fg-disabled, #999);
  }

  &.icon:before {
    content: "";
    display: inline-block;
    height: 20px;
    width: 20px;
  }

  &.basket:before {
    background-image: url("../../../images/shopping-cart.svg");
  }

  &.link-external:before {
    background-image: url("../../../images/link-external.svg");
  }

  &.user-plus:before {
    background-image: url("../../../images/user-plus.svg");
  }

  &.user-x:before {
    background-image: url("../../../images/user-x.svg");
  }
}

.product-detail__product-price-info-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  .price-info-content {
    display: none;

    &.open {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
    }
  }

  .price-info-row-basic {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    span {
      color: var(--Dark-Night-Black-100, #122020);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }

  .price-info-row {
    @extend .price-info-row-basic;

    span {
      font-weight: 700;
      color: var(--Colors-Text-text-tertiary, #808080);

      &:last-child {
        color: var(--Colors-Text-text-primary, #1a1a1a);
      }
    }

    &.sub-price-info {
      padding-left: 16px;

      span {
        font-weight: 400;
      }
    }
  }

  .price-info-headline {
    @extend .price-info-row-basic;
    cursor: pointer;

    span {
      font-weight: 700;
    }

    .price-info-toggle {
      width: 16px;
      height: 16px;
      background-image: url("../../../images/down_icon.svg");
      background-position: center;
      background-repeat: no-repeat;

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  .remaining-due-row {
    @extend .price-info-row-basic;
    padding-top: 16px;
    align-items: flex-start;
    border-top: 1px solid #cedee7;

    span {
      font-weight: 700;

      &:last-child {
        color: var(--Dark-Night-Black-60, #717979);
        font-size: 12px;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
      }
    }

    .price-with-vat {
      display: flex;
      width: 274px;
      flex-direction: column;
      align-items: flex-end;
    }
  }
}

.price-popup {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  h3 {
    margin: 0 32px 16px 32px;
  }

  p {
    margin-left: 32px;
    margin-right: 32px;
  }
}
