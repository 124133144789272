.btn-2019 {
  display: inline-block;
  padding: 7px 27px;
  color: #fff;
  border-radius: 4px;
  background: var(--base-color);
  min-width: 190px;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  line-height: 26px;
  transition: all 0.15s ease-in-out;
  @include media("<md") {
    font-size: 14px;
  }
  .val {
    font-weight: 700;
  }
  &-icon {
    line-height: 0;
    display: inline-block;
    margin: 0 25px 0 0;
    vertical-align: middle;
    svg path {
      fill: currentColor;
    }
    + span {
      position: relative;
      top: 3px;
    }
  }
  &:hover {
    color: #fff;
    text-decoration: none;
  }

  .dark-theme & {
    color: #393c40;
  }
}

a.btn-2019:hover {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}

a.btn-2019--ghost:hover {
  box-shadow: none !important;
}

.btn-2019--ghost {
  background: none;
  border: 2px solid #fff;
  color: #fff;
  font-weight: 700;
  padding: 9px 27px 5px;
}

.btn-favorite {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 7px 10px 8px 34px;
  position: relative;
  outline: 0;
  margin-bottom: 20px;

  svg {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    width: 18px;
  }
}

.button-primary {
  display: inline-block;
  background: var(--base-color);
  font-size: 16px;
  padding: 1em 2em;
  line-height: 1em;
  color: #fff;
  border-radius: 1.5em;
  text-decoration: none;
  font-weight: 700;

  @include media("<md") {
    font-size: 12px;
  }

  &:hover {
    color: #fff;
    text-decoration: none;
  }
}
