/*------------------------------------*\
  Profile
\*------------------------------------*/

/*
* Visible at:
* all screen sizes
*
* Content:
* Container
* Columns
* First column
* Last column
* Tabs
* Content in tabs
* Service message
* Mails/messages
* STATE: new message
*
*/

/*------------------------------------*\
  Container
\*------------------------------------*/

.mf-c_profile-container-overview {
  background-color: $mf-color-white;
  @include mf-box-shadow;
  padding: $layout-digit * 1.5 0;
  text-align: center;
  @media screen and (min-width: $screen-sm) {
    padding: $layout-digit * 1.5;
  }
  @media screen and (min-width: $screen-md) {
    text-align: left;
  }
  .mf-typo_decent {
    // IE8
    height: auto !important;
    // IE8
    height: 2.857em;
    // IE8
    min-height: 2.857em;
  }
}

/*------------------------------------*\
  Columns
\*------------------------------------*/

.mf-c_profile-column {
  padding-top: $layout-digit;
  padding-bottom: $layout-digit;
  @media screen and (min-width: $screen-md) {
    padding-top: 0;
    padding-bottom: 0;
  }
  .mf-typo_number-strong {
    margin-bottom: 0;
  }
}

/*------------------------------------*\
  First column
\*------------------------------------*/

.mf-c_profile-column-first {
  border-bottom: 1px solid $mf-color-gray-medium;
  margin-bottom: $layout-digit;
  @media screen and (min-width: $screen-md) {
    border-bottom: none;
    margin-bottom: 0;
  }
}

/*------------------------------------*\
  Last column
\*------------------------------------*/

.mf-c_profile-column-last {
  border-top: 1px solid $mf-color-gray-medium;
  margin-top: $layout-digit;
  padding-top: $layout-digit * 2;
  @media screen and (min-width: $screen-xs) {
    left: 0%;
  }
  @media screen and (min-width: $screen-sm) {
    left: 0%;
    margin-top: 0;
  }
  @media screen and (min-width: $screen-md) {
    padding-top: 0;
    border-top: none;
  }
}

/*------------------------------------*\
  Tabs
\*------------------------------------*/

.mf-c_profile-container-tabs {
  @include mf-tabs;
  .mf-c_profile-tabactive {
    display: inline;
    margin-left: 10px;
  }
}

/*------------------------------------*\
  Content in tabs
\*------------------------------------*/

.mf-c_profile-tab-content {
  position: relative;
  @include mf-box-shadow;
  p {
    margin-top: $layout-digit;
  }
  dt,
  dd {
    font-weight: 500;
    text-align: left;
  }
  dt {
    padding-top: $layout-digit;
    width: 170px;
  }
  dd {
    @media screen and (min-width: $screen-sm) {
      padding-top: $layout-digit;
    }
  }
}

/*------------------------------------*\
  Message: service
\*------------------------------------*/

.mf-c_profile-servicetext {
  margin-top: $layout-digit;
  position: relative;
  @media screen and (min-width: $screen-sm) {
    margin-left: $layout-digit;
  }
  .glyphicon {
    position: absolute;
    left: calc($layout-digit * -1.5);
  }
}
