.no-results {
  display: flex;
  flex-direction: column;
  width: 748px;
  padding: 73.5px 54px 74.5px 53px;
  height: 312px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @include media("<md") {
    width: 100%;
    padding: 10px;
  }

  span {
    color: #000;
    text-align: center;
    font-size: 24px;
    line-height: normal;
  }

  button {
    display: flex;
    padding: 17px 69px;
    justify-content: center;
    align-items: center;
    border-radius: 27px;
    background: #3d8a9d;
    cursor: pointer;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
  }

  a {
    color: #000;
    text-align: center;
    font-size: 16px;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.no-results-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 516px;

  @include media(">xl") {
    height: 903px;
  }
}
