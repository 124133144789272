html {
  scroll-behavior: smooth;
}

.sticky-button {
  display: flex;
  min-height: 44px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  position: fixed;
  right: 32px;
  bottom: 32px;
  border-radius: 9999px;
  border: 1px solid #c7c7c7;
  background: #fff;
  text-decoration: none;
}

.sticky-button:hover {
  color: inherit;
  text-decoration: none;
  background: #f5f5f5;
}

@media (max-width: 768px) {
  .sticky-button span {
    display: none;
  }
}

.survey-card {
  display: flex;
  padding: 32px;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  margin-bottom: 70px;
}

.thumb-container {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.button {
  display: flex;
  padding: 32px;
  border-radius: 8px;
  background: #f5f5f5;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
}

.button:hover {
  background: #ebebeb;
}
