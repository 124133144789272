@charset 'utf-8';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ol,
  ul {
    @apply pl-10;
    @apply my-4;
  }

  ol {
    @apply list-decimal;
  }

  ul {
    @apply list-disc;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    @apply text-black;
  }
}

.ql-align-right {
  @apply text-right;
}
.ql-align-center {
  @apply text-center;
}
.ql-align-justify {
  @apply text-justify;
}

// ----------------------------------------
//         STYLES COMPILED WITH SASS
//
// ----------------------------------------
//
//
// -------------------------------- Vendors
@import "../__vendors/normalize";
@import "../__vendors/include-media";

// -------------------------------- Helpers
@import "__helpers/fonts";
@import "__helpers/variables";
@import "__helpers/mixins";
@import "__helpers/breakpoints";
@import "__helpers/general";
@import "__helpers/typography";
@import "__helpers/glyphicons";

// ----------------------------- Components
@import "__components/__header/alerts";
@import "__components/__header/header";
@import "__components/__header/health-goals";
@import "__components/__footer/footer";
@import "__components/__elements/buttons";
@import "__components/__elements/dropdown";
@import "__components/__elements/loader";
@import "__components/__elements/notification";
@import "__components/__elements/modal";
@import "__components/__elements/error";
@import "__components/__elements/pagination";
@import "__components/__elements/slider";
@import "__components/__page/items-grid";
@import "__components/__page/items-grid-utils";
@import "__components/__page/items-grid-single";
@import "__components/__page/items-grid-tiles";
@import "__components/__page/links-section";
@import "__components/__page/card-service";
@import "__components/__page/filter";
@import "__components/__page/map";
@import "__components/__page/no-results";
@import "__components/__page/card-article";
@import "__components/__page/totp";
@import "__components/__page/checkout-summary";
@import "__components/__page/imprint";
@import "__components/__page/product-card";
@import "__components/__page/account";
@import "__components/__page/health-profile";
@import "__components/__page/tell-a-colleague";
@import "__components/activity";
@import "__components/__page/product-detail";
@import "__components/__page/campaign-landing";
@import "__components/__page/product-price-box";
@import "__components/__page/bgm-info";

@import "__stepchall/basics";
@import "__stepchall/header";
@import "__stepchall/alert-bar";
@import "__stepchall/routeview";
@import "__stepchall/teamlist";
@import "__stepchall/leaderboard";
@import "__stepchall/nav-bar";
@import "__stepchall/faq";
@import "__stepchall/my-steps";
@import "__stepchall/my-team";
@import "__stepchall/modal";
@import "__stepchall/details";
@import "__stepchall/tooltip";

@import "assessment";
@import "slider";
