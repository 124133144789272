.imprint {
  background-color: #aacbd0;
  background-size: 100%;
  padding-top: 20px;

  @media screen and (max-width: 767px) {
    background-image: none;
  }

  h2 {
    font-weight: 500;
    font-size: 2em;
    line-height: 1.1;
  }

  pre {
    overflow: auto;
    max-width: 1165px;
    padding: 2.286em;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 5% auto;
    background: #f5f5f5;
    font-size: 13px;
  }
}

.imprint-header {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0 20px;
  align-items: center;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    padding: 0;
    align-items: end;
  }

  h1 {
    color: #fff;
    font-size: 3.571em;
    text-transform: capitalize;
    max-width: 700px;
    display: none;

    @media screen and (min-width: 768px) {
      display: block;
      width: 600px;
      text-transform: unset;
    }
  }

  &:before,
  &:after {
    content: "";
    background-repeat: no-repeat;
    display: block;
    background-size: contain;
    background-position: bottom;
  }

  &:before {
    background-image: url("../../../../../static/img/sporty-person.svg");
    width: 168px;
    height: 150px;
    margin-right: 10px;
    display: none;

    @media screen and (min-width: 1450px) {
      display: block;
    }
  }

  &:after {
    background-image: url("../../../../../static/img/sporty-group.svg");
    width: 300px;
    height: 95px;

    @media screen and (min-width: 768px) {
      width: 600px;
      height: 190px;
    }
  }
}

.imprint--container {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  padding: 15px;
}
