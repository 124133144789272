.header {
  border-bottom: 1px solid #e7e8e8;
  padding: 12px 0;
  font-size: 14px;
  line-height: 24px;

  @include media("<lg") {
    padding: 21px 0;
  }
}

.header-container {
  border-bottom: 1px solid #e7e8e8;
  font-size: 14px;
  line-height: 24px;
  box-shadow: 0 7px 21px rgba(0, 0, 0, 0.07);
}

.global-filter {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  position: relative;

  @include media(">sm") {
    display: flex;
    gap: var(--spacing-md, 8px);
  }

  @include media("<sm") {
    &.mobile-open {
      display: flex;
      position: fixed;
      background: #fff;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2000;
      border-radius: var(--radius-lg, 16px);
    }
  }

  .global-filter-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-md, 8px);
    align-self: stretch;
    padding: 16px var(--container-padding-mobile, 16px);

    @include media(">sm") {
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacing-xs, 4px);
      padding: 0;
    }

    .global-filter-keyword {
      display: flex;
      flex-direction: row;
      padding: 10px 14px;
      align-items: center;
      gap: var(--spacing-md, 8px);
      align-self: stretch;
      justify-content: space-between;

      border-radius: var(--radius-full, 9999px);
      border: 1px solid var(--Colors-Border-border-primary, #c7c7c7);
      background: var(--Colors-Background-bg-primary, #fff);
      height: 44px;
      width: 100%;

      @include media(">sm") {
        width: 300px;
      }

      > input[type="text"] {
        display: flex;
        align-items: center;
        gap: var(--spacing-md, 8px);
        align-self: stretch;
        width: inherit;
        padding: 0 0 0 30px;
        border: none;
        background-color: transparent;
        background-image: url("../../../images/search-md.svg");
        background-position: left center;
        background-repeat: no-repeat;
        outline: 0;
        overflow: hidden;
        color: var(--Colors-Text-text-primary, #1a1a1a);
        text-overflow: ellipsis;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        &::placeholder {
          color: #9399a3;
        }
      }

      button {
        cursor: pointer;
        display: flex;
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("../../../images/x-circle.svg");
      }
    }

    .search-bar-mobile-close {
      @include media(">sm") {
        display: none;
      }

      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--spacing-md, 8px);
      padding: 12px;
      flex-shrink: 0;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("../../../images/x-close.svg");
      background-size: 20px 20px;
      border-radius: var(--radius-full, 9999px);
    }
  }

  .search-bar-dropdown {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 0 0;

    @include media(">sm") {
      width: 300px;
      gap: var(--spacing-sm, 8px);
      position: absolute;
      top: 47px;
      z-index: 3000;
      border-radius: var(--radius-lg, 16px);
      background: var(--Colors-Background-bg-primary, #fff);
      box-shadow:
        0 8px 16px 4px rgba(0, 0, 0, 0.08),
        0 3px 8px 0 rgba(0, 0, 0, 0.06);
      padding: var(--spacing-xs, 4px) 0;
    }

    &.open {
      display: flex;
    }

    button.search-bar-dropdown__content {
      display: flex;
      min-height: 44px;
      padding: 10px 16px;
      align-items: center;
      align-self: stretch;

      border-radius: 12px;

      color: var(--Colors-Text-text-secondary, #4d4d4d);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      background-color: transparent;

      @include media(">sm") {
        cursor: pointer;
        min-height: 42px;
        flex: 1 0 0;
        border-radius: 0;

        &:first-child {
          border-bottom: 1px solid var(--Colors-Border-border-secondary, #ebebeb);
        }
      }
    }
  }
}

.header__icon-search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: var(--radius-full, 9999px);
  background: var(--Component-Colors-Buttons-Tertiary-button-tertiary-bg, #fff);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../../../images/search-md.svg");

  @include media(">sm") {
    display: none;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 20px;

  @media only screen and (max-width: 1200px) {
    flex-wrap: wrap;
    padding-bottom: 0;
    width: 100% !important;

    .header__flex {
      background-color: #fbfbfb;
      order: 3;
      margin: 0 -20px;
      width: calc(100% + 40px);
      padding: 24px 20px;
    }
  }

  @media only screen and (max-width: 700px) {
    .header__logo {
      max-width: 160px;
    }

    .header__flex {
      flex-wrap: wrap;
      padding: 10px 20px 10px;
      margin-top: 10px;

      nav {
        order: 2;
        width: 100%;
      }
    }

    .global-filter-keyword-input {
      margin-bottom: 10px;
      width: 100%;
    }
  }
}

.header__flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-nav {
  display: flex;
  padding: 10px;
  list-style: none;
  justify-content: center;
  font-size: 16px;

  div {
    margin: 0 12px;

    a {
      text-decoration: none;
      color: #000;

      &:hover {
        text-decoration: underline;
      }

      &.active {
        font-weight: 700;
      }
    }
  }

  @include media("<lg") {
    padding: 10px 0;
    justify-content: space-between;
    gap: 5px;

    div {
      margin: 0;
    }
  }
}

.header__logo {
  img {
    max-height: 100px;
    max-width: 180px;

    @include media("<md") {
      max-height: 48px;
      max-width: 160px;
    }

    @include media("<sm") {
      max-width: 140px;
    }

    @include media("<xs") {
      max-width: 100px;
    }
  }
}

.header__budget {
  display: flex;
  flex-direction: column;
  align-items: end;
  border-radius: 25px;
  transition: all ease-in-out 0.2s;
  text-decoration: none;
  color: #000;
  padding: 10px 20px;
  max-width: 250px;

  &:hover {
    background-color: #f3f3f3;
    color: #000;
  }

  span {
    font-size: 12px;
    margin-right: 4px;
  }

  @include media("<=sm") {
    padding: 0;
  }
}

.header__budget-info {
  display: flex;
  align-items: center;
}

.header__budget-label {
  @include media("<=sm") {
    display: none !important;
  }
}

.header__budget-amount {
  font-size: 24px;
  color: #008ca0;
  font-weight: 700;

  @include media("<=sm") {
    font-size: 16px;
  }
}

.header__budget-comment {
  color: #8f8f8f;
  font-size: 12px;
  font-weight: 400;
  text-align: right;
  line-height: 15px;

  @include media("<=sm") {
    width: 160px;
  }
}

.header__utilities {
  display: flex;
  align-items: center;

  > * {
    @include media("<md") {
      margin-left: 10px;
    }
  }
}

.header__hochbahn {
  white-space: nowrap;

  @include media("<md") {
    display: none;
  }
}

.header__icon-link {
  border: 0;
  outline: 0;
  background-color: transparent;
  font-size: 12px;
  cursor: pointer;
  border-radius: 25px;
  padding: 5px 15px;
  transition: all ease-in-out 0.2s;

  &:hover {
    background-color: #f3f3f3;
  }
}

.header__dropdown {
  position: relative;
  margin-left: 0;
}

.header__dropdown-content {
  position: absolute;
  top: 100%;
  left: -100%;
  margin-top: 10px;
  border: 1px solid #bfbfbf;
  border-radius: 16px;
  box-shadow: 0px 0px 22px rgba(0, 0, 0, 0.15);
  transform: translateX(-50%);
  background-color: #fff;
  z-index: 2100;
  min-width: 200px;
  width: 245px;
  font-size: 16px;
  padding: 2px;

  .header__icon-link {
    cursor: default;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 10px 0 0;

    &:not(:last-child) {
      border-bottom: 1px solid #e7e8e8;
    }

    li {
      margin-bottom: 10px;

      a {
        padding: 3px 24px;
        display: flex;
        padding-left: 10px;
        align-items: center;
        width: 100%;
        text-decoration: none;
        color: inherit;

        i,
        img {
          margin-right: 10px;
          height: 19px;
          width: 19px;
        }

        &:hover {
          background-color: #f6f6f6;
        }
      }
    }
  }
}

.nav-featured-link {
  font-weight: 700;
}

.header__dropdown-content-divider {
  height: 2px;
  border: none;
  background-color: #f6f6f6;
  margin: 2px 0;
}

.main-logo {
  font-size: 0;
  line-height: 0;
  display: inline-block;
  margin: 0;
  max-width: 180px;

  img {
    max-height: 100px;
  }

  cursor: pointer;
}

.main-header {
  &-top {
    display: flex;
    padding: 45px 0 35px;
    align-items: center;
    @include media("<lg") {
      padding: 30px 0 20px;
    }

    &-left {
      display: flex;
      max-width: 30%;
      padding: 8px 0 0 5px;
      align-items: center;
      flex: 0 0 30%;
    }

    &-right {
      display: flex;
      max-width: 70%;
      flex: 0 0 70%;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &-bottom {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &-left {
      display: flex;
      max-width: 80%;
      flex: 0 0 80%;
      align-items: center;
      @include media("<md") {
        max-width: 100%;
        flex: 0 0 100%;
        justify-content: space-between;
      }
    }

    &-right {
      max-width: 20%;
      text-align: right;
      flex: 0 0 20%;
      @include media("<md") {
        max-width: 100%;
        text-align: center;
        flex: 0 0 100%;
      }
    }
  }
}

.hamburger-menu {
  padding: 0;
  cursor: pointer;
  outline: 0;
  background: transparent;
  box-shadow: none;

  span {
    margin-bottom: 6px;
    display: block;
    width: 20px;
    height: 1px;
    background: $color-black;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include media(">=md") {
    display: none !important;
  }
}

@keyframes hide-assessment-tooltip {
  to {
    visibility: hidden;
  }
}

.hide-assessment-tooltip {
  animation: hide-assessment-tooltip 0s 5s forwards;
}

.breadcrumbs-container {
  padding: 24px;
  display: flex;
  justify-content: space-between;

  @include media("<md") {
    display: none;
  }
}

.mobile-breadcrumbs-container {
  display: none;

  .breadcrumbs {
    a:before {
      content: url("../../../images/breadcrumb-mobile.svg");
      position: absolute;
      top: 0;
      left: 0;
      height: 18px;
      width: 18px;
    }

    a {
      padding-left: 20px;
    }

    a:after {
      display: none;
    }
  }

  @include media("<md") {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 32px;
  }
}

.breadcrumbs {
  color: #000;
  font-size: 16px;
  line-height: normal;
  position: relative;

  a {
    position: relative;
    margin: 0 10px;
  }

  a:first-of-type {
    margin-left: 0;
  }

  span {
    margin-left: 10px;
  }

  a:after {
    content: url("../../../images/breadcrumbs-separator.svg");
    position: absolute;
    top: 0;
    right: -20px;
    height: 18px;
    width: 18px;
  }

  .last-breadcrumb {
    color: #bebebe;
    font-size: 16px;
    line-height: normal;
  }
}
