#health-expense-page {
  background-color: #f6f6f6;
}

#health-expense {
  display: flex;
  width: 100%;
  @media screen and (max-width: $screen-md) {
    flex-direction: column;
  }
}

#health-expense > div:nth-child(1) {
  flex: 1 1 auto;
  max-width: 400px;
  margin-right: 20px;
  @media screen and (max-width: $screen-md) {
    max-width: none;
    margin-right: auto;
    margin-left: auto;
  }
}

#health-expense > div:nth-child(2) {
  flex: 1 1 auto;
  margin-top: 40px;
  @media screen and (max-width: $screen-md) {
    margin-top: 0px;
  }
}

#health-expense-form {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 10px 25px 0 rgba(112, 144, 176, 0.2);
  display: flex;
  flex-direction: column;
  padding: 21px;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;

  input,
  select {
    height: 40px;
    padding: 0 18px;
  }

  textarea {
    height: 100px;
    padding: 9px 18px;
  }

  select {
    cursor: pointer;
  }

  input,
  select,
  textarea {
    width: 100%;
    border-radius: 6px;
    margin-bottom: 18px;
    line-height: 1.5;

    &:focus {
      border: solid 1px #0073dc;
      outline: none;
    }
    &::placeholder {
      letter-spacing: 0.5px;
    }
  }

  .files-list {
    list-style: none;
    margin-left: 0;
    padding-left: 0;

    label {
      width: 100%;
    }

    input[type="file"] {
      display: none;
    }
  }

  .privacy-policy {
    display: flex;
    align-items: center;
    line-height: 1.33;

    input {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      margin-right: 20px;
    }
  }

  .privacy-policy:nth-child(1) {
    flex: 0 1 auto;
  }

  .privacy-policy:nth-child(2) {
    flex: 1 1 auto;
  }

  .button {
    width: 100%;
    height: 40px;
    border-radius: 26px;
    box-shadow: 0 2px 5px 0 rgba(18, 32, 32, 0.1);
    color: white;
    background-color: var(--base-color);
    border: none;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.5px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .user-note {
    font-weight: normal;
  }

  // Switch the displayed order of input and label so that the label is on top
  .amount-field .input-field {
    display: flex;
    flex-direction: column;

    input {
      order: 1;
    }

    label {
      order: 0;
    }
  }
}
