.map-popup__product-card {
  background: #ffffff;
  border: 1px solid #cecece;
  border-radius: 6px;
  width: 380px;
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translate(-50%, 0);
  overflow: hidden;
  box-shadow: 0 4px 22px rgba(0, 0, 0, 0.32);

  a {
    display: flex;
  }
}

.map-popup__product-thumbnail {
  flex-basis: 140px;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0;
  background-size: cover;
}

.map-popup__content {
  padding: 24px 16px;
}

.map-popup__category {
  font-size: 12px;
  font-weight: 700;
  color: #a2a2a2;
  margin-bottom: 8px;
}

.map-popup__title {
  font-size: 16px;
  color: #202020;
  margin-bottom: 4px;
}

.map-popup__partner {
  display: block;
  font-size: 12px;
  line-height: 16px;
}

.map-popup__address {
  display: block;
  color: #a2a2a2;
  line-height: 16px;
  font-size: 12px;
}
