.error {
  select,
  input {
    border-color: $mf-color-error;
  }
}

.horizontal-radio-select {
  list-style-type: none;
  display: inline-block;
  padding: 0;

  li {
    display: inline-block;
    margin-right: 20px;
  }
}

.password-with-reveal i {
  pointer-events: unset;
  top: 0;
  cursor: pointer;
}

.errorlist {
  margin: 0 0 0 20px;
  padding: 0 0 10px 0;
}

.errorlist li {
  padding: 0 0 5px 0;
  list-style: none;
}
