.featured-article {
  display: flex;
  flex: 0 1 100%;
  height: 100%;
  position: relative;
  border-radius: 4px;
  overflow: hidden;

  &:hover {
    .bg-wrapper {
      transform: scale(1.05);
    }
  }

  &:after {
    content: "";
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.48) 100%);
    height: 100%;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
  }
}

// FIXME
/* stylelint-disable-next-line no-descending-specificity */
.bg-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  transition: all 0.2s linear;
  margin: 0;
}

.featured-article__intro {
  display: inline-block;
  align-self: flex-end;
  padding: 100px 50px;
  z-index: 1;
  color: #fff;

  h3 {
    font-size: 30px;
    vertical-align: bottom;
    font-weight: 700;
    line-height: 1.2em;
    max-width: 580px;
  }
}

.articles-list {
  padding: 30px 20px 0;
  list-style: none;
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    @include cf;
    margin-bottom: 20px;
    flex: 0 0 50%;

    @include media("<lg") {
      flex: 0 0 100%;
    }

    a {
      &:hover {
        .img-wrapper--inner {
          transform: scale(1.1);
        }
      }
    }

    .img-wrapper {
      float: left;
      width: 30%;
      margin: 0;
      position: relative;
      overflow: hidden;

      &:before {
        content: "";
        display: block;
        padding-top: 64%;
      }

      // FIXME
      /* stylelint-disable-next-line no-descending-specificity */
      .img-wrapper--inner {
        background-size: cover;
        background-position: center;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.2s linear;
      }
    }

    .article-intro {
      float: left;
      padding-left: 20px;
      width: 70%;
      margin-bottom: 20px;

      h4 {
        font-size: 17px;
        margin-bottom: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      p {
        font-size: 14px;
        color: #757575;
        margin-bottom: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .more {
        font-size: 14px;
        text-decoration: underline;
      }
    }
  }
}
