//
// ----------------------------------------
// COLORS

:root {
  --base-color: #488cff;
  --base-color-dark: #488cff;
  --base-color-light: #488cff;
  --base-color-lighter: #488cff;
}

$color-black: #393c40;
$color-bg: #f9f9f9;
$color-bg-2: #383b41;
// $color-1: #3bbd83;
$color-1: #f30c00;
$color-2: #d8d8d8;
$color-3: #144794;
$color-4: #9f9f9f;

$border: rgba(159, 159, 159, 0.25);
$border-2: #969696;

$shadow: 0 0 5px rgba(0, 0, 0, 0.1);
$shadow-2: 0 2px 10px rgba(0, 0, 0, 0.05);
$shadow-3: 0 5px 20px rgba(0, 0, 0, 0.15);

$text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

$box-gradient: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
$box-gradient-2: linear-gradient(180deg, transparent 56%, rgba(0, 0, 0, 0.3) 100%);
$box-gradient-3: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
