//
// ----------------------------------------
// TYPOGRAPHY
//
//
a {
  text-decoration: none;
  color: inherit;
}

p {
  margin: 0 0 11px;

  a {
    text-decoration: underline;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 40px;
  font-weight: 800;
  line-height: 50px;
  margin: 0 0 17px;
  @include media("<md") {
    font-size: 20px;
    line-height: 26px;
    margin: 0 0 9px;
  }
}

h2 {
  font-size: 40px;
  font-weight: 800;
  line-height: 50px;
  @include media("<xl") {
    font-size: 34px;
    line-height: 40px;
  }
  @include media("<md") {
    font-size: 20px;
    line-height: 26px;
  }
}

h3 {
  font-size: 25px;
  font-weight: 400;
  line-height: 34px;
  margin: 0 0 17px;
  @include media("<md") {
    font-size: 18px;
    line-height: 26px;
  }
}

h4 {
  font-size: 20px;
  font-weight: 800;
  line-height: 26px;
  margin: 0 0 17px;
  @include media("<md") {
    font-size: 15px;
    line-height: 20px;
  }
}

h5 {
  font-size: 15px;
  font-weight: 900;
  line-height: 20px;
}

h6 {
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  margin: 0 0 9px;
}
