/*------------------------------------*\
  Social media
\*------------------------------------*/

/*
 * The Social Media links
 * in the dark footer
 *
 * Content:
 * default styles
 * facebook
 * twitter
 *
*/

/*------------------------------------*\
  Default styles
\*------------------------------------*/

.supplier-social-icons {
  margin-top: 10px;
  display: inline-block;

  .social-link {
    text-decoration: none;
    width: 30px;
    height: 30px;
    display: inline-block;
    color: #9f9f9f;

    &:hover {
      color: var(--base-color);
    }
  }
}
