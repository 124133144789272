/*------------------------------------*\
  Messages
\*------------------------------------*/

/*
 * Content:
 * Help message
 *
 */

/*------------------------------------*\
  Help message
\*------------------------------------*/

.mf-c_message-help {
  margin-top: 4em;
  text-align: center;

  @media screen and (min-width: $screen-sm) {
    margin-top: 0;
    text-align: left;
  }

  @media screen and (min-width: 1200px) {
    margin-left: 100px;
  }
}

.messages .alert {
  margin: $line-height-computed 0;
}
