.sc_my-steps {
  padding: 30px 20px;
}

.sc_donut {
  position: relative;
  margin: 40px auto;
  text-align: center;
}

.sc_donut-square {
  display: inline-block;
  position: relative;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.sc_donut-label {
  text-align: center;
  font-size: 1.5rem;
  padding: 0 2.3em;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  min-width: 120px;
  box-sizing: initial;

  .daily-target {
    font-size: 1.25rem;
  }

  span.unit {
    display: block;
    font-size: 0.6em;
    margin: 5px 0 -5px;
  }

  hr {
    border: 0;
    border-top: 1px solid #e7e8e8;
  }
}

svg.donut {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.donut-segment {
  animation: chart-loading-1 1s linear both;
  opacity: 0;
}

.donut-dot {
  animation: chart-loading-dot 2s linear forwards;
}

.reached-daily-target {
  animation: bounceIn 0.6s forwards;
  opacity: 0;
  position: absolute;
  right: 10px;
  top: 0;
  max-width: 80px;

  .emoji {
    font-size: 1.5rem;
    display: block;
    margin-bottom: 5px;
  }

  .factor {
    font-size: 0.625rem;
    line-height: 1.4em;
    display: inline-block;
    text-transform: uppercase;
  }
}

@keyframes chart-loading-1 {
  0% {
    stroke-dasharray: 0 100;
  }
  1% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes chart-loading-dot {
  0% {
    transform: rotate(0deg);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.sc_bar-charts {
  height: 180px;
  position: relative;
  overflow-x: scroll;
  display: flex;
  flex-direction: row-reverse;
  margin: 40px -15px 40px -15px;
  padding: 20px 60px 30px 15px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.sc_bar-charts-inner {
  height: 100%;
  white-space: nowrap;
  border-bottom: 1px solid $sc_border-color;
  position: relative;
  align-items: flex-end;
  display: flex;
}

.sc_bar-chart {
  background-color: $sc_primary-color;
  margin: 0 6px;
  width: 20px;
  display: inline-block;
  vertical-align: bottom;
  position: relative;
}

.sc_bar-charts-average {
  width: 100%;
  position: absolute;
  height: 2px;
  border-bottom: dashed 1px black;

  span {
    position: absolute;
    left: calc(100% + 10px);
    font-size: 10px;
    color: $sc_border-color;
    top: -8px;
  }
}

.sc_bar-chart-weekday {
  position: absolute;
  bottom: -30px;
  font-size: em(12);
  left: 50%;
  transform: translateX(-50%);
}

.sc_bar-chart-weekday-bold {
  font-weight: 700;
  line-height: 1.45em;
}

.sc_bar-chart-separator {
  background-color: $sc_primary-color;
  color: $sc_primary-color;
  width: 1px;
  height: 50px;
  position: absolute;
  bottom: 0;
  font-size: em(10);
  position: absolute;
  left: -6px;

  span {
    position: absolute;
    bottom: -18px;
    transform: translateX(-50%);
  }

  &.end {
    left: auto;
    right: -6px;
  }
}

.sc_activity-log-list {
  margin-top: 10px;
}

.sc_activity-log-item {
  border: 1px solid $sc_border-color;
  border-radius: 4px;
  margin-bottom: 3px;
  padding: 10px;
  @extend .sc_justify-space;
  @extend .sc_vertical-center;

  > div:first-child {
    width: 85%;
  }

  &.deleted {
    opacity: 0.4;
    text-decoration: line-through;
  }

  h3 {
    margin-bottom: 0;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    margin-bottom: 0;
    font-size: em(14);

    > * {
      vertical-align: middle;
    }
  }

  button {
    background: none;
    font-size: 0.6em;
    outline: 0;
  }
}

.sc_add-activity {
  background: $sc_primary-color;
  background-image: url("../../images/machtfit-rib.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  color: #fff;
  min-height: 100%;

  .sc_container {
    min-height: 100vh;
    display: flex;
    padding: 15px 15px 30px;
    flex-direction: column;

    @media only screen and (min-width: 768px) {
      min-height: 0;
    }

    .sc_content-top {
      flex: 1;
    }
  }

  .sc_back {
    margin-bottom: 15px;

    svg > g > g {
      fill: #fff;
    }
  }
}

.sc_separator {
  text-align: center;
  position: relative;
  font-size: em(12);
  margin: 30px 0;
  display: flex;
  align-items: center;
  padding: 0 20px;

  span {
    padding: 0 10px;
    position: relative;
    white-space: nowrap;
    background: none;
    font-weight: 700;
  }

  &:before,
  &:after {
    content: "";
    height: 1px;
    width: 100%;
    background-color: #fff;
  }
}

.sc_activity-list {
  background-color: #fff;
  border-radius: 4px;
  color: $sc_text-color;
  padding: 10px 0;
}

.sc_activity-list-item {
  display: block;
  padding: 10px 15px;
  cursor: pointer;
}

.sc_activity-input {
  display: flex;
  align-items: center;

  input {
    margin-right: 20px;
    width: 100px;
    border: 0;
  }

  label {
    color: #fff;
    font-size: 1em;
    font-weight: 400;
  }
}

.sc_charts-navigation {
  border-radius: 21px;
  box-shadow: 0 16px 40px 0 rgba(112, 144, 176, 0.2);
  border: solid 0.3px rgba(190, 196, 206, 0.5);
  display: flex;
  overflow: hidden;
}

.sc_charts-navigation-button {
  border-left: 1px solid rgba(190, 196, 206, 0.5);
  background-color: #fff;
  color: $sc_text-color;
  flex-grow: 1;
  outline: 0;
  padding: 12px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.75em;
  text-transform: uppercase;
  cursor: pointer;

  &:first-child {
    border: 0;
  }

  &.active {
    font-weight: 700;
    letter-spacing: -0.003rem;
  }
}

.day-reference-alert {
  background-color: $sc_raspberry;
  color: #fff;
  font-size: 0.75rem;
  margin: 0 -15px 15px;
  line-height: 1.333em;
  padding: 1.25rem 1rem;
}
