.single-item {
  &-wrapper {
    border-radius: 4px;
    position: relative;
    display: block;
    max-width: 100%;
    border: 1px solid $border;
    background: #fff;
    box-shadow: $shadow;
    flex: 0 1 100%;
    transition: all 0.08s ease-in-out;
    &:hover {
      box-shadow: $shadow-3;

      .single-item-thumb__wrapper {
        transform: scale(1.05);
      }

      .read-more {
        text-decoration: underline;
      }
    }
  }
  &-content {
    padding: 20px 20px 40px;

    .content-text {
      position: relative;
      z-index: 10;
      display: -webkit-box;
      max-height: 206px;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      -webkit-line-clamp: 5;
      margin-bottom: 24px;
    }
  }
  &-distance {
    font-weight: 800;
    font-size: 14px;
    text-align: center;
    height: 78px;
    width: 78px;
    border-radius: 50%;
    background: white;
    margin: -69px 0 0 auto;
    position: relative;
    display: block;
    padding-top: 8px;

    .single-item-distance__icon {
      line-height: 1em;

      svg {
        display: inline-block;
      }
    }
  }
  &-sub {
    height: 26px;
    position: relative;
    z-index: 10;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 1;
    margin: 0 0 10px;
  }
  &-heading {
    position: relative;
    z-index: 10;
    display: -webkit-box;
    height: 52px;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
    margin: 0 0 24px;
    @include media("<md") {
      height: auto;
      margin: 0 0 4px;
    }
    h4 {
      margin: 0;
    }
  }
  &-thumb {
    position: relative;
    margin: 0 0 30px;
    padding-top: 60.5%;
    overflow: hidden;
    background-size: cover;

    .full-img & {
      margin: -20px -20px 30px;
      padding-top: 82%;
    }

    .single-item-thumb__blurry {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      filter: blur(7px);
      opacity: 0.6;
      background-size: cover;
      background-position: center;
    }

    // FIXME
    /* stylelint-disable-next-line no-descending-specificity */
    .single-item-thumb__wrapper {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      transition: all 0.2s linear;
    }
  }
  &-labels {
    position: absolute;
    z-index: 7;
    left: 0;
    .single-label {
      position: relative;
      z-index: 8;
      &:hover,
      &:focus {
        .single-label-tooltip {
          display: block;
        }
      }
      &-tooltip {
        /* stylelint-disable-line no-descending-specificity */ // FIXME
        font-weight: 800;
        position: absolute;
        bottom: 100%;
        left: 50%;
        display: none;
        min-width: 95px;
        margin-bottom: 16px;
        padding: 5px;
        transform: translate(-50%, 0);
        color: $color-black;
        border-radius: 4px;
        background: $color-2;
        &:before {
          position: absolute;
          top: 100%;
          left: 50%;
          display: block;
          width: 0;
          height: 0;
          content: "";
          transform: translate(-50%, 0);
          border-width: 8px 10px 0;
          border-style: solid;
          border-color: $color-2 transparent transparent transparent;
        }
      }
    }
    &.top-labels {
      top: 0;
      padding: 16px 55px 0 16px;
      .single-label {
        color: #fff;
        background: $color-1;
      }
    }
    &.bottom-labels {
      bottom: 0;
      left: 0;
      right: auto;
      padding: 16px 16px 8px;
      // @include media('<md')
      // {
      //     display: none;
      // }
      .single-label {
        font-weight: 400;
        padding: 4px 11px;
        border-radius: 15px;
        background-color: #fff;
        border: 1px solid #eee;
      }
    }
    .special-wrapper {
      // margin-top: -16px;
      // @include media('<md')
      // {
      //     display: none;
      // }
    }
    &.single-item-special {
      &:before {
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        border: 2px solid $color-1;
      }
    }
  }
  &-rows {
    padding: 4px 0 70px;
    // @include media('<md')
    // {
    //     padding: 0 0 40px;
    // }
    a {
      text-decoration: underline;
    }
  }
  &-row {
    line-height: 24px;
    position: relative;
    margin-bottom: 8px;
    margin-bottom: 10px;
    padding-left: 23px;
    display: block;
    // @include media('<xl')
    // {
    //     padding-left: 20px;
    // }
    // @include media('<md')
    // {
    //     font-size: 12px;
    // }
    &:before {
      position: absolute;
      top: 5px;
      left: 0;
      display: block;
      content: "";
      background-repeat: no-repeat;
      background-size: contain;
    }
    p {
      margin: 0;
    }
  }
  &-date {
    &:before {
      width: 14px;
      height: 14px;
      background-image: url("../../../images/calendar.svg");
    }
    .weekdays {
      .weekday {
        &:after {
          content: " | ";
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
      }
      .weekday__time {
        &:after {
          content: " & ";
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }
  &-time {
    // @include media('<md')
    // {
    //     display: none;
    // }
    &:before {
      top: 5px;
      width: 14px;
      height: 14px;
      background-image: url("../../../images/clock.svg");
    }
  }
  &-address {
    // @include media('<md')
    // {
    //     .single-line ~ *
    //     {
    //         display: none;
    //     }
    // }
    &:before {
      top: 4px;
      width: 14px;
      height: 14px;
      background-image: url("../../../images/location.svg");
    }
  }
  &-bottom {
    border-top: 1px solid #dfdfdf;
    line-height: 30px;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    min-height: 30px;
    padding: 30px 20px;
    @include cf();
    // @include media('<xl')
    // {
    //     padding: 17px 15px;
    // }
    .text {
      float: left;
      // @include media('<md')
      // {
      //     display: none;
      // }
    }
    .optional-price {
      font-weight: 800;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: 5px;
      margin-left: -14px;
      transform: translate(-50%, -50%);
      text-decoration: line-through;
      color: #d7d7d7;
      // @include media('<md')
      // {
      //     font-size: 14px;
      //     line-height: 20px;
      //
      //     position: static;
      //
      //     float: left;
      //
      //     margin-left: 0;
      //
      //     transform: translate(0, 0);
      // }
    }
    .price {
      font-size: 20px;
      font-weight: 800;
      float: right;
      // @include media('<md')
      // {
      //     font-size: 15px;
      //     line-height: 20px;
      // }
    }
  }
}

.item-highlight {
  border: 2px solid $color-1;
}

.single-line {
  position: relative;
}

.card-product-dev-1947 {
  width: 100%;
  align-items: flex-start;
  gap: var(--spacing-sm, 8px);
  align-self: stretch;
  padding: 0;

  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--Colors-Border-border-secondary, #ebebeb);
  background: var(--Colors-Background-bg-primary, #fff);
  position: relative;
  flex-direction: column;

  .image-container-dev-1947 {
    position: relative;
    width: 100%;

    .image-dev-1947 {
      aspect-ratio: 140/100;
      width: auto;
    }

    .card-badges-dev-1947 {
      position: absolute;
      bottom: 0;
      margin: 16px;
    }
  }

  .content-dev-1947 {
    word-break: break-word;
    padding: 8px 16px 16px 16px;
    height: 100%;
    justify-content: space-between;
  }

  .campaign-badges-dev-1947 {
    position: absolute;
    top: 0;
    margin: 8px;
  }

  @include media("<sm") {
    flex-direction: row;
    padding: 8px;

    .image-container-dev-1947 {
      width: 140px;

      .image-dev-1947 {
        width: inherit;
        align-self: stretch;
        height: auto;
        img {
          border-radius: 4px;
        }
      }

      .card-badges-dev-1947 {
        position: static;
        margin: unset;
      }
    }

    .content-dev-1947 {
      padding: unset;
      height: auto;
    }

    .campaign-badges-dev-1947 {
      position: static;
      margin: unset;
    }
  }
}

.card-dev-2004 {
  grid-template-areas:
    "secondary-image image"
    "secondary-image content";
  word-break: break-word;
}

.card-dev-2004--reverted {
  grid-template-areas:
    "image secondary-image"
    "content secondary-image";
}

.card-dev-2004--reverted-mobile {
  @media (max-width: 640px) {
    grid-template-areas:
      "image secondary-image"
      "content secondary-image";
  }
}

.card-dev-2004--single-image {
  grid-template-areas:
    "image image"
    "content content";
}
