// Wrapped into .main-header to avoid conflicts with existing dropdowns on platform
.main-header {
  .dropdown {
    &-wrapper {
      position: relative;
      display: inline-block;
    }
    &-trigger {
      font-size: 15px;
      line-height: 26px;
      position: relative;
      margin: 0;
      padding: 7px 20px;
      cursor: pointer;
      border: 0;
      background: transparent;
      box-shadow: none;
      svg {
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
        width: 9px;
        height: 5px;
        content: "";
        transform: translate(0, -50%);
        path {
          stroke: currentColor;
        }
      }
    }
    &-content {
      font-size: 14px;
      line-height: 26px;
      position: absolute;
      z-index: 4;
      top: 100%;
      left: 9px;
      display: none;
      min-width: 150px;
      padding: 0 0 6px;
      border: 1px solid #d6d6d6;
      background: #fff;
      box-shadow: $shadow;
      .open + & {
        display: block;
      }
      ul {
        padding: 13px 0 0;
        margin: 0;
        li {
          list-style-type: none;
        }
        + ul {
          position: relative;
          margin-top: 13px;
          padding: 10px 0 0;
          &:before {
            position: absolute;
            top: 0;
            right: 20px;
            left: 10px;
            height: 1px;
            content: "";
            background: $color-2;
          }
        }
      }
      a {
        display: block;
        padding: 4px 18px 4px 10px;
        &.current,
        &:hover {
          color: $color-black;
          background: $color-2;
        }
      }
    }
  }
  .dropdown-menu:not(.dropdown-list) {
    // Overwriting 2014 CSS for Dropdowns
    top: auto !important;
    float: none !important;
    min-width: 0 !important;
    padding: 0 !important;
    font-size: 15px !important;
    text-align: -webkit-auto !important;
    background: none !important;
    border: 0 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    &:hover,
    &.open {
      .dropdown-list {
        display: block;
      }
    }
  }
}

.dropdown-fixed {
  width: 100%;

  > button {
    font-size: 16px;
    color: #000;
    border-radius: 27px;
    height: 54px;
    background-color: #fff;
    outline: 0;
    border: 1px solid #868686;
    position: relative;
    cursor: pointer;
    margin-right: 5px;
    padding: 0 14px 0 36px;
    white-space: nowrap;
    background-image: url("../../../images/filter.svg");
    background-repeat: no-repeat;
    background-position: left 14px center;

    @include media(">md") {
      display: none;
    }

    &.active {
      font-weight: 700;
      border-color: #000;
    }

    &:hover {
      border-color: #000;
    }
  }

  .dropdown-menu {
    position: fixed;
    display: none;
    background: #fff;
    bottom: 0;
    width: 100%;
    z-index: 2000;
    flex-direction: column;
    min-height: 500px;
    padding: 20px;
    border: 1px solid #bfbfbf;
    border-radius: 16px;
    box-shadow: 0 0 22px rgba(0, 0, 0, 0.22);
    right: 0;

    @include media(">md") {
      position: relative;
      flex-direction: row;
      display: flex;
      min-height: unset;
      border: none;
      box-shadow: none;
      padding: 20px 0;
      background: transparent;
    }

    @include media("<md") {
      .filter-dropdown__content {
        position: fixed;
        background: #fff;
        bottom: 0;
        width: 100% !important;
        z-index: 200;
        flex-direction: column;
        min-height: 500px;
        padding: 20px;
        top: unset;
        max-width: unset;
        left: 0;

        .scroll {
          height: unset;
          max-height: 300px;
        }
      }
    }

    > header {
      display: grid;
      grid-template-columns: 1fr auto 1fr;

      @include media(">md") {
        display: none;
      }

      h5 {
        grid-column-start: 2;
        font-size: 20px;
        font-weight: bold;
        color: #000;
        align-self: center;
      }

      button {
        background-color: transparent;
        margin-left: auto;
        border: none;
        width: auto;
        height: auto;

        &:disabled {
          opacity: 0.15;
        }
      }
    }

    &.open {
      display: flex;
    }

    .filter-dropdown__button {
      border: none;

      @include media(">md") {
        border: 1px solid #868686;
      }
      @include media("<md") {
        background-image: url("../../../images/arrow-right.svg");
        text-align: left;
      }
    }

    > .primary {
      align-self: center;
      background-color: #008ca0;
      border-radius: 27px;
      border: 1px solid #008ca0;
      color: #fff;
      cursor: pointer;
      font-family: "CeraPro";
      font-feature-settings: "ss01" 1;
      font-size: 16px;
      font-weight: bold;
      height: 44px;
      margin: 20px 0;
      padding: 0;
      width: 280px;

      @include media(">md") {
        display: none;
      }
    }
  }
}
