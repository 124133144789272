/*------------------------------------*\
  Project specific variables
\*------------------------------------*/

/*
 * The following variables are used,
 * when the same value is needed
 * more than once
 *
 * Content:
 * layout digit
 * aside width
 * scrollbar width
 * logo width
 * product image width
 * footer heights
 */

/*------------------------------------*\
  Layout digit
  a small value for harmonic margins
  and paddings,
  can be calculated with,
  e.g. $layout-digit * 2
\*------------------------------------*/

// 16 / 14
$layout-digit: 1.143em;

/*------------------------------------*\
  Logo width
  Maximum width value for all
  logo ratios
\*------------------------------------*/

$c_logo-width-standard: 80px;
$c_logo-width-landscape: 140px;
$c_logo-width-portrait: 50px;

/*------------------------------------*\
  Product image
\*------------------------------------*/

$product-image-width: 220px;

/*------------------------------------*\
  Footer heights
\*------------------------------------*/

$l-footer-height-desktop: 22.2em;
$l-footer-height-mobile: 43.571em;
