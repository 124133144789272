.sc_tooltip {
  background: white;
  padding: 6px 10px;
  min-width: 110px;
  max-width: 200px;
  display: block;
  position: absolute;
  box-shadow: 0 2px 6px 0 rgba(#000, 0.3);
  border-radius: 8px;
  font-size: 0.625rem;

  p {
    margin: 0 0 2px;
    line-height: 0.75rem;
  }

  &:after {
    content: "";
    width: 0;
    height: 0;
    border: 10px solid transparent;
    position: absolute;
  }
}

.sc_tooltip_team {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sc_tooltip-top {
  transform: translate3d(-50%, calc(-100% - 15px), 0);

  &:after {
    border-bottom: none !important;
    border-top-color: #fff;
    left: calc(50% - 10px);
    bottom: -8px;
  }
}

.sc_tooltip-left {
  transform: translate3d(15px, -50%, 0);

  &:after {
    border-left: none !important;
    border-right-color: #fff;
    bottom: calc(50% - 10px);
    left: -8px;
  }
}

.sc_tooltip-right {
  transform: translate3d(calc(-100% - 15px), -50%, 0);

  &:after {
    border-right: none !important;
    border-left-color: #fff;
    bottom: calc(50% - 10px);
    left: auto;
    right: -8px;
  }
}

.sc_tooltip-bottom {
  transform: translate3d(-50%, 15px, 0);

  &:after {
    border-top: none !important;
    border-bottom-color: #fff;
    border-top: none;
    top: -8px;
    bottom: auto;
  }
}

.sc_tooltip-own-team {
  background-color: #122020;
  color: #fff;
  padding: 6px 6px 3px;
  min-width: 85px;
  border-radius: 6px;
  text-align: center;

  p {
    font-size: 0.875rem;
  }

  &:after {
    border: 5px solid transparent;
  }

  &.sc_tooltip-top {
    &:after {
      border-top-color: #122020;
      left: calc(50% - 5px);
      bottom: -5px;
    }
  }

  &.sc_tooltip-left {
    &:after {
      border-right-color: #122020;
      bottom: calc(50% - 6px);
      left: -5px;
    }
  }

  &.sc_tooltip-right {
    &:after {
      border-left-color: #122020;
      bottom: calc(50% - 6px);
      left: auto;
      right: -5px;
    }
  }

  &.sc_tooltip-bottom {
    &:after {
      border-bottom-color: #122020;
      left: calc(50% - 5px);
      top: -5px;
      bottom: auto;
    }
  }
}
