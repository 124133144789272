.sc_route-view {
  position: relative;
  margin-bottom: 4.2rem;

  path.route {
    stroke-dasharray: 1 18;
    stroke-dashoffset: 0;
    animation: dash 2s linear infinite;
    fill: none;
    stroke-linecap: round;
    stroke-width: 6px;
  }

  @keyframes dash {
    from {
      stroke-dashoffset: 0;
    }
    to {
      stroke-dashoffset: -19;
    }
  }
}

.sc_route-view-interaction {
  .button-group {
    border-radius: 6px;
    overflow: hidden;
    margin-bottom: 3px;

    button {
      box-shadow: 0px 1px 3px 0px rgba(113, 121, 121, 0.5);
      background: rgba(#122020, 0.7);
      margin-bottom: 1px;
      display: block;
      width: 100%;
      color: #fff;
      font-size: 0.75rem;
      text-transform: uppercase;
      padding: 10px 5px;
      cursor: pointer;
      text-align: center;
      min-width: 50px;

      &.active {
        color: $sc_primary-color;
        font-weight: 700;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
