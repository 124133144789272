.campaign-header {
  display: flex;
  max-width: 1520px;
  padding: 72px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  flex-wrap: wrap;
  text-align: center;

  .campaign-header-buttons-container {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 16px;
    width: 100%;

    @include media("<md") {
      flex-direction: column;
      padding: 0;
    }
  }
}

.campaign-section--heading {
  padding: 0px 32px;
  align-items: flex-start;
  max-width: 1520px;
  width: 1254px;
  color: #1a1a1a;
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;

  @include media("<md") {
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0;
  }
}

.campaign-inspiration {
  display: flex;
  max-width: 1520px;
  padding: var(--font-line-height-display-xl, 72px) var(--spacing-xl, 32px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xl, 32px);
  align-self: stretch;
}
