.modal {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.67);
  z-index: 3000;

  .modal__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}

.modal-popup {
  width: calc(100vw - 32px);
  max-width: 521px;
  border-radius: 16px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding-top: 32px;
  padding-bottom: 32px;

  p:last-of-type {
    margin-bottom: 0;
  }

  .close {
    cursor: pointer;
    position: absolute;
    right: 21px;
    top: 21px;
    background-color: transparent;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    // background-position: center;
    background-image: url("../../../images/close-2023.svg");
  }

  h3 {
    color: #000;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.72px;
    margin-bottom: 0;
  }
}
