@import "../styles-2020/_colors.scss";
@import "../styles-2020/_breakpoints.scss";
@import "../styles-2020/_language-switcher.scss";

.assessment-body {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  margin: auto;

  @media only screen and (max-width: 720px) {
    margin: 0 20px;
  }

  h1 {
    color: rgb(18, 32, 32);
    font-family: "CeraPro";
    font-feature-settings: "ss01" 1;
    font-size: 36px;
    font-weight: normal;
    line-height: 40px;
    margin-bottom: 16px;

    & + p {
      color: rgb(18, 32, 32);
      font-family: "CeraPro";
      font-feature-settings: "ss01" 1;
      font-size: 20px;
      font-weight: normal;
      line-height: 28px;
      margin-bottom: 8px;
    }
  }

  p {
    color: rgb(18, 32, 32);
    font-family: "CeraPro";
    font-feature-settings: "ss01" 1;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    margin-bottom: 32px;
  }

  h2 {
    color: rgb(18, 32, 32);
    font-family: "CeraPro";
    font-feature-settings: "ss01" 1;
    font-size: 24px;
    font-weight: normal;
  }

  .button {
    width: 100%;
    height: 40px;
    border-radius: 26px;
    box-shadow: 0 2px 5px 0 rgba(18, 32, 32, 0.1);
    background-color: rgb(52, 183, 143);
    border: none;
    font-family: "CeraPro";
    font-feature-settings: "ss01" 1;
    font-size: 16px;
    font-weight: bold;

    line-height: 1.25;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 20px;

    &:disabled {
      opacity: 0.5;
    }

    &:enabled {
      cursor: pointer;
    }
  }

  .progress-bar {
    display: flex;
    margin-bottom: 46px;
    margin-top: 66px;

    > div {
      background: rgb(231, 232, 232);
      height: 4px;
      width: 50px;
      margin-right: 8px;

      &.active {
        background: rgb(52, 183, 143);
      }
    }
  }

  .answer-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media only screen and (max-width: 720px) {
      justify-content: center;
    }
  }

  .answer {
    display: flex;
    background: rgb(255, 255, 255);
    border-radius: 6px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    height: 62px;
    width: 320px;
    align-items: center;
    margin-bottom: 40px;
    cursor: pointer;
    border: 1px solid transparent;
    overflow: hidden;

    span {
      max-width: 200px;
    }

    &.single {
      width: 100%;
      max-width: 100%;

      span {
        max-width: 100%;
        @media only screen and (max-width: 500px) {
          max-width: 280px;
        }
      }
    }

    .checkmark {
      background-repeat: no-repeat;
      margin-left: auto;
      margin-right: 10px;
      height: 24px;
      width: 24px;
      background-image: url("../images/icons_checkboxes_unselected.svg");

      &.active {
        background-image: url("../images/icons_checkboxes_selected.svg");
      }
    }

    &.active {
      border-color: rgb(52, 183, 143);
    }

    img {
      width: 77px;
      height: 100%;
    }

    span {
      margin-left: 8px;
      color: rgb(18, 32, 32);
      font-family: "CeraPro";
      font-feature-settings: "ss01" 1;
      font-size: 16px;
      font-weight: normal;
      line-height: 20px;
    }
  }
}

.assessment {
  background: #f8f9fa;

  .max-answers-reached {
    opacity: 0.5;
  }

  .container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }

  .close {
    height: 24px;
    width: 24px;
    display: flex;
    background-repeat: no-repeat;
    background-image: url("../images/icons_close.svg");
    position: absolute;
    top: 56px;
    right: 20px;
    cursor: pointer;

    @media only screen and (max-width: 800px) {
      top: 20px;
    }
  }

  .back {
    height: 24px;
    width: 24px;
    display: flex;
    cursor: pointer;
    background-repeat: no-repeat;
    background-image: url("../images/icons_arrows_left.svg");
    position: absolute;
    top: 56px;
    left: 20px;

    @media only screen and (max-width: 800px) {
      top: 20px;
    }
  }

  .welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h1 {
      margin-top: 100px;
    }
  }

  .goodbye {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .circle {
      margin-top: 129px;
      margin-bottom: 40px;
      height: 90px;
      width: 90px;
      background-repeat: no-repeat;
      background-image: url("../images/Illustrations_Flat_Target.svg");
    }

    .health-profile-button {
      padding: 5px;
      width: 250px;
      border: 1px solid #393c40;
      border-radius: 20px;
      margin-bottom: 30px;
    }
  }

  header {
    padding: 25px 0;
    background: rgb(255, 255, 255);
    box-shadow:
      0px 1px 3px 0px rgba(17, 53, 58, 0.1),
      0px 1px 6px -2px rgba(17, 53, 58, 0.14);

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  footer {
    background: rgb(255, 255, 255);
  }

  .privacy {
    border: 1px solid rgb(231, 232, 232);
    border-left: 0;
    border-right: 0;
    background: #f8f9fa;
    text-align: center;

    div {
      height: 128px;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 640px;
      padding: 0 20px;
      margin: auto;
      font-family: "CeraPro";
      font-feature-settings: "ss01" 1;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      color: rgb(18, 32, 32);
    }
  }

  .copyright {
    border-radius: 0px;
    height: 104px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 640px;
    margin: auto;
    color: rgb(18, 32, 32);
    font-family: "CeraPro";
    font-feature-settings: "ss01" 1;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
  }
}
