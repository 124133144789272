.sc_leaderboard {
  margin-top: 1rem;
  min-height: 440px;
}

.sc_leaderboard-header {
  vertical-align: top;
  padding: 0 20px;
  display: flex;
  text-transform: uppercase;
  margin-bottom: 0.25rem;

  > div {
    padding: 0.75rem;
    border: 0;

    &.member {
      flex: none;
      padding-left: 0;
    }

    &.steps {
      padding: 0.75rem 0;
      flex: none;
      margin-left: auto;
      font-size: 0.9em;
    }
  }
}

.sc_leaderboard-stage {
  background: #e7e8e8;
  font-weight: 700;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d7d8d8;
  animation: leaderboardIn 0.2s linear;
  animation-fill-mode: both;
  opacity: 0;

  &.goal {
    background-color: $sc_primary-color;
    color: #fff;
    border: 0;

    #flag {
      fill: #fff;
    }
  }
}

@keyframes leaderboardIn {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.content-placeholder {
  display: block;
  height: 1.2em;
  width: 1.2rem;
  background: #e7e7e7;
  border-radius: 0.6em;

  .name & {
    width: 10rem;
  }

  .steps & {
    width: 3rem;
  }
}

.sc_leaderboard-row {
  cursor: pointer;
  vertical-align: top;
  border-bottom: 1px solid #e7e8e8;
  padding: 0 20px;
  display: flex;
  animation: leaderboardIn 0.2s linear;
  animation-fill-mode: both;
  opacity: 0;

  &.own {
    background-color: #eaf7f3;
  }

  > div {
    padding: 0.75rem;
    border: 0;

    &.rank {
      flex: none;
      padding-left: 0;
    }

    &.color {
      padding: 0.75rem 0;
      flex: none;
    }

    &.name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.steps {
      text-align: right;
      padding-right: 0;
      padding-left: 0;
    }

    &.goal-reached-date {
      color: $sc_primary-color;
      font-size: 0.75rem;
      font-weight: 700;
      padding-right: 0.25rem;
      white-space: nowrap;

      #flag {
        fill: $sc_primary-color;
      }
    }

    &.push-right {
      margin-left: auto;
    }
  }

  .team-color {
    display: block;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    margin-top: 0.175rem;
  }

  .team-member-count {
    display: block;
    font-size: 0.75em;
  }
}

.sc_leaderboard-nav {
  text-align: center;
  margin: 2rem;

  .inner {
    display: inline-flex;
    position: relative;

    &:after {
      bottom: 0;
      content: "";
      display: block;
      position: absolute;
      width: 40%;
      height: 1px;
      background-color: #008ca0;
      transition: all 0.2s linear;
    }

    &.scope-teams {
      &:after {
        left: 5%;
      }
    }

    &.scope-participants {
      &:after {
        left: 55%;
      }
    }
  }

  button {
    background: none;
    outline: none;
    font-size: 2rem;
    padding: 0.5rem;
    position: relative;
    min-width: 4rem;
    margin: 0 10px;
    cursor: pointer;

    .sc_icon {
      margin-right: 0;
    }

    &.active {
      .colored {
        stroke: #008ca0;
      }
    }

    &:after {
      content: "";
      width: 1px;
      background-color: #e7e8e8;
      display: block;
      height: 100%;
      position: absolute;
      right: -10px;
      top: 0;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}

.sc_pagination {
  margin: 2rem auto 0;
  display: flex;
  text-align: center;
  width: 230px;
  align-items: center;
  border-bottom: 1px solid #e7e8e8;
  padding-bottom: 1rem;

  > div {
    flex-basis: 33.33%;
  }

  button {
    background: none;
    padding: 0;
    outline: 0;
    border: 1px solid #000;
    border-radius: 6px;
    height: 35px;
    cursor: pointer;
  }
}

.sc_to-first-page {
  border-bottom: 1px solid #e7e8e8;
  margin: 0 auto;
  padding: 1rem 0;
  text-align: center;
  font-weight: 700;
  width: 230px;

  button {
    background: 0;
    outline: 0;
    cursor: pointer;
  }
}
