/*------------------------------------*\
  Project specific variables
\*------------------------------------*/

/*
  * The following variables are used,
  * when the same value is needed
  * more than once
  *
  * Content:
  *
  * LAYOUT SETTINGS
  * Full height possibility
  * Main container
  * Aside containers
  * Fullscreen image container
  * Content container
  * Standard row
  * Footer
  * Footer top container
  * Footer push
  * Background image fullscreen
  * Floating image
  *
  * VISUAL SETTINGS
  * Main elements
  * Main container
  * Aside container
  * Content container
  * Footer container
  *
  */

/*------------------------------------*\
  LAYOUT SETTINGS
\*------------------------------------*/

/*------------------------------------*\
  Main container
  The container which includes everything
  except the side menu
\*------------------------------------*/

.mf-l_main {
  padding-top: $layout-digit;
  width: 100%;
  min-height: 100%;
  min-height: 100%;
  height: 100%;
  margin: 0 auto (-$l-footer-height-mobile) auto;
  @media screen and (min-width: $screen-md) {
    margin: 0 auto (-$l-footer-height-desktop) auto;
  }
}

/*------------------------------------*\
  Fullscreen image content container
\*------------------------------------*/

.mf-l_fullscreen-content-container {
  width: 100%;
  @media screen and (min-width: $screen-md) {
    padding-top: 20px;
    padding-bottom: $layout-digit * 8;
  }
}

/*------------------------------------*\
  Content container
\*------------------------------------*/

.mf-l_container-content {
  padding-top: $layout-digit * 3;
  padding-bottom: $layout-digit * 2;

  &.product-list {
    padding-top: 1em;
  }

  .container {
    @media screen and (min-width: $screen-sm) {
      padding-left: $layout-digit * 2;
      padding-right: $layout-digit * 2;
    }
  }
}

.mf-l_container-content-small {
  padding-top: $layout-digit;
  padding-bottom: $layout-digit;
}

/*------------------------------------*\
  Standard Machtfit rows
  overwriting bootstrap rows when
  margin is needed
\*------------------------------------*/

.mf-l_row {
  margin-top: $layout-digit * 2;
  margin-bottom: $layout-digit * 2;
}

.mf-l_row-small {
  margin-top: $layout-digit;
  margin-bottom: $layout-digit;
}

/*------------------------------------*\
  Footer
\*------------------------------------*/

/* make footer sticky */

.mf-l_footer-push {
  height: $l-footer-height-mobile;
  @media screen and (min-width: $screen-md) {
    height: $l-footer-height-desktop;
  }
}

.mf-l_footer-push {
  background-color: $mf-color-white-dark;
}

/*------------------------------------*\
  Background images
\*------------------------------------*/

.mf-l_fullscreen-background-image {
  background-color: #aacbd0;
  background-size: 100%;
  background-repeat: no-repeat;

  @media screen and (max-width: 767px) {
    background-image: none;
  }
}

.mf-headline-background-fit-people {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0 20px;
  align-items: center;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    padding: 0;
    align-items: end;
  }

  h2 {
    max-width: 700px;
    display: none;
    color: #fff;

    @media screen and (min-width: 768px) {
      display: block;
      width: 600px;
      text-transform: unset;
    }
  }

  &:before,
  &:after {
    content: "";
    background-repeat: no-repeat;
    display: block;
    background-size: contain;
    background-position: bottom;
  }

  &:before {
    background-image: url("../../../../static/img/sporty-person.svg");
    width: 168px;
    height: 150px;
    margin-right: 10px;
    display: none;

    @media screen and (min-width: 1450px) {
      display: block;
    }
  }

  &:after {
    background-image: url("../../../../static/img/sporty-group.svg");
    width: 300px;
    height: 95px;

    @media screen and (min-width: 768px) {
      width: 600px;
      height: 190px;
    }
  }
}

/*------------------------------------*\
  VISUAL SETTINGS
\*------------------------------------*/

/*------------------------------------*\
  Main elements
\*------------------------------------*/

body {
  background-color: $mf-color-white;
}

/*------------------------------------*\
  Content container
\*------------------------------------*/

.mf-l_container-content-gray {
  background-color: $mf-color-white-dark;
  background-color: $mf-color-white-dark-semi-transparent;
}

.mf-l_container-content-white {
  background-color: $mf-color-white;
}
