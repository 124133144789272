/*------------------------------------*\
  Product headlines
\*------------------------------------*/

/*
* Visible at:
* all screen sizes
*
* Content:
* Container
* Numbers
* Headline
* Radio buttons (mobile)
* STATE: no filter applied (default)
* STATE: filter applied
*
*/

/*------------------------------------*\
  Container
\*------------------------------------*/

.notification-bg {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 19999;
  overflow: auto;

  .notification {
    position: relative;
    background: #fff;
    border: 1px solid #eee;
    margin: 3em auto;
    width: 50%;
    padding: 1em;
    display: none;
    border-radius: 5px;
    @include mf-box-shadow;
    @media screen and (max-width: 570px) {
      width: 100%;
      margin: 0 auto;
    }
  }

  .notification-bottom {
    border-top: 1px solid #eee;
    bottom: 0;
    margin-top: 1em;
    padding-top: 1em;
    text-align: right;
    @media screen and (max-width: 700px) {
      text-align: left;
    }
  }
}

.container--slider {
  padding-bottom: 0;
  margin-bottom: -15px;

  @media screen and (max-width: 570px) {
    display: none;
  }

  .slide > a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.bx-wrapper {
  border: 15px solid #fff;
  border-bottom: 5px solid #fff;
}
