//
// ----------------------------------------
// GENERAL
//
// The most general styles for
// <html>, <body> and .container
//

body {
  font-family: $font--text;
  -webkit-font-smoothing: antialiased;
  color: $color-black;
  font-size: 15px;
  line-height: 26px;
  min-width: 320px;

  // Define an explicit background colors because some browsers (e.g. Firefox) allow to define a
  // user/system defined background color which can lead to unexpected results.
  background-color: white;

  &.overlayed {
    position: relative;
    &:before {
      content: "";
      display: block;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      background: rgba(0, 0, 0, 0.67);
      z-index: 10;
    }
  }
}
#page {
  overflow: hidden;
  position: relative;
}

#startpage {
  &.overlaid {
    position: relative;
    &:before {
      content: "";
      display: block;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      background: rgba(0, 0, 0, 0.67);
      z-index: 11;
    }
  }
}
.container {
  max-width: 1530px;
  margin: 0 auto;
  padding: 0 15px;

  @include media("<xl") {
    max-width: 1165px;
    width: 100%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

iframe,
button {
  line-height: inherit;
  font-size: inherit;
}

button {
  color: inherit;
  font: inherit;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;

  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-before: 0;
  -webkit-padding-start: 0;
  -webkit-padding-end: 0;
  -webkit-padding-after: 0;
  min-width: auto;
}

.mobile-hide {
  @include media("<md") {
    display: none;
  }
}

.mobile-show {
  display: none;
  @include media("<md") {
    display: block;
  }
}

.col-4 {
  max-width: 33.33%;
  flex: 0 0 33.33%;
  @include media("<lg") {
    max-width: 50%;
    flex: 0 0 50%;
  }
}

.col-4-lg {
  @include media("<lg") {
    max-width: 50%;
    flex: 0 0 50%;
  }
  @include media("<sm") {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.col-6 {
  @include media("<sm") {
    max-width: 100%;
    flex: 0 0 100%;
  }
  flex: 0 0 50%;
  max-width: 50%;
}

.col-8 {
  @include media("<sm") {
    max-width: 100%;
    flex: 0 0 100%;
  }
  flex: 0 0 66.66666%;
  max-width: 66.66666%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.grid-sm-12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 15px;

  .row-card {
    margin: auto 15px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 100%;

    ::after,
    ::before {
      content: " ";
      display: table;
    }

    ::after {
      clear: both;
    }

    .grid-sm-8 {
      padding: 0 15px;
      flex-basis: 66.67%;
      max-width: 66.67%;
      @include media("<=lg") {
        flex-basis: 66.67%;
        max-width: 66.67%;
      }
      @include media("<=md") {
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    .grid-sm-4 {
      padding: 0 15px;
      flex-basis: 33.33%;
      max-width: 33.33%;

      @include media("<=lg") {
        flex-basis: 33.33%;
        max-width: 33.33%;
      }
      @include media("<=md") {
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    .grid-sm-4-card {
      padding: 0 15px;
      flex-basis: 25%;
      max-width: 25%;

      @include media("<=lg") {
        flex-basis: 33.33%;
        max-width: 33.33%;
        &:last-of-type {
          display: none;
        }
      }
      @include media("<=md") {
        &:last-of-type {
          display: block;
        }
      }
      @include media("<=sm") {
        flex-basis: 100%;
        max-width: 100%;
        &:last-of-type {
          display: none;
        }
      }
    }

    hr,
    h2 {
      flex-basis: 100%;
    }
  }
}
