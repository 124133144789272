/*------------------------------------*\
  Typographic settings
\*------------------------------------*/

/*
* Content:
* Project specific variables
* Classes for character formatting
* Error message
*/

/*------------------------------------*\
  Project specific settings
  overwriting bootstrap settings
\*------------------------------------*/

body {
  color: $mf-color-text-default !important;
  font-family: "CeraPro", sans-serif;
  font-feature-settings: "ss01" 1;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: 400;
}

a {
  color: $mf-color-link;
  text-decoration: underline;
  &:hover,
  &:focus {
    color: $mf-color-link-hover;
  }
}

.forgotten-password {
  color: $mf-color-gray-blue;
  text-decoration: none;
  &:hover {
    color: $mf-color-link-visible;
    text-decoration: underline;
  }
}

.nav,
.navbar,
.dropdown,
.dropdown-menu,
.panel {
  a {
    text-decoration: none;
  }
}

.lead {
  a {
    color: $mf-color-link-visible;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

a.btn {
  text-decoration: none;
}

/*------------------------------------*\
  Classes for character formatting
\*------------------------------------*/

.mf-typo_number-strong {
  color: var(--base-color);
  font-size: 3.571em;
  font-weight: 900;
  margin-top: calc($layout-digit / -6);
  small {
    font-size: 0.32em;
  }
}

.mf-typo_camelcase {
  text-transform: none;
}

.mf-typo_color-white {
  color: $mf-color-white;
}

.mf-typo_color-main {
  color: var(--base-color);
}

.mf-typo_decent {
  color: $mf-color-gray-medium-dark;
}

.mf-typo_first {
  margin-top: 0 !important;
}

.mf-typo_last {
  margin-bottom: 0;
}

.mf-typo_headline-huge {
  // 50 / 14
  font-size: 3.571em;
}

.mf-typo_headline-big {
  // 30 / 14
  font-size: 2.143em;
  @media screen and (max-width: 400px) {
    font-size: 1.8em;
    word-wrap: break-word;
  }
}

.mf-typo_headline-medium {
  // 28 / 14
  font-size: 2em;
}

.mf-typo_headline-small {
  // 28 / 14
  font-size: 1.75em;
}

.mf-typo_headline-space-big {
  margin-top: $layout-digit * 2;
}

.mf-typo_small {
  color: $mf-color-gray-standard;
}

.mf-typo_underlined {
  border-bottom: 1px solid $mf-color-gray-standard;
  display: inline-block;
  padding-bottom: calc($layout-digit / 2);
}

.mf-typo_uppercase {
  text-transform: uppercase;
}

.mf-typo_weight-strong {
  font-weight: 900;
}

/*------------------------------------*\
  Error message
\*------------------------------------*/

.error {
  color: $mf-color-error;
}

.error-block {
  color: $mf-color-error;
}

.errorlist {
  color: $mf-color-error;
}

/*------------------------------------*\
  Phone numbers
\*------------------------------------*/

.tel {
  color: var(--base-color);
}

.is-p20 {
  display: block;
  font-size: 12px;
  font-weight: normal;
  color: #9f9f9f;
  text-align: inherit;

  .mf-typo_number-strong & {
    margin-top: -5px;
  }
}
