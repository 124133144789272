.checkout-summary {
  width: 100%;
}

.checkout-summary__section {
  border-bottom: 1px solid #d8d5d5;
  padding: 20px 0;

  &:last-of-type {
    border: 0;
  }
}

.checkout-summary__row {
  display: table;
  padding: 5px 0;
  width: 100%;

  .left {
    display: table-cell;
    width: 100%;
    vertical-align: top;
    padding-right: 30px;
  }

  .right {
    display: table-cell;
    vertical-align: top;
    white-space: nowrap;
  }
}

.checkout-summary__sm {
  font-size: 12px;
}

.checkout-summary__md {
  font-size: 14px;
  font-weight: 700;
}

.checkout-summary__lg {
  font-size: 16px;
  font-weight: 700;
}

.checkout-summary__xl {
  font-size: 24px;
  font-weight: 700;
}

.input-gift-container {
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  i {
    position: absolute;
    left: 15px;
    top: 10px;
    color: #cfcfcf;
  }

  input {
    font-size: 14px;
    padding: 9px 40px 8px;
    border: 0;
    width: calc(100% - 40px);
  }

  button {
    float: right;
    border: 0;
    border-radius: 0;
    color: #828282;
    font-size: 12px;
    background: #e3e3e3;
    width: 40px;
    padding: 10px 0 9px;
  }
}

.checkout-summary__submit {
  padding: 20px 0;

  a {
    font-size: 16px;
    padding: 16px 20px;
    width: 100%;
    font-weight: 700;
  }
}

.checkout-summary__remove-voucher {
  input {
    text-decoration: underline;
    background: none;
    padding: 0;
    border: 0;
  }
}

.checkout-information {
  border-radius: 4px;
  border: 1px solid var(--base-color);
  padding: 20px 20px 15px;
}

.checkout-product-tile {
  padding: 0;
  margin-bottom: 20px;
  border-radius: 4px;
  margin-top: 25px;
  margin-bottom: 30px;

  h4 {
    font-weight: 500;
    font-size: 20px;

    small {
      margin-top: 10px;
    }
  }

  .mf-c_product-detail-overview-content {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  img {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  }
}

.checkout {
  padding: 30px 0;
}
