.footer {
  font-size: 15px;
  box-shadow: 0px -1px 3px 0px rgba(231, 232, 232, 0.5);
  background-color: #fff;
}

.footer__top {
  border-bottom: 1px solid #e7e8e8;
  padding: 2.75em 0 2.125em;

  .container {
    display: flex;
    justify-content: space-between;
    &:before {
      content: none;
    }
    &:after {
      content: none;
    }
  }

  @include media("<md") {
    background-color: #f6f9fa;
  }
}

.footer__logo {
  display: flex;
  align-items: center;
  gap: 1em;

  @include media("<md") {
    display: none;
  }
}

.footer__logo-link {
  img {
    max-height: 100px;
    max-width: 180px;

    @include media("<md") {
      max-height: 48px;
      max-width: 160px;
    }
  }
}

.footer__cooperation {
  display: flex;
  gap: 0.5em;
  align-items: center;

  span {
    margin-top: 0.8em;
    font-size: 0.75em;
    text-transform: uppercase;
  }

  img {
    height: 1.5em;
  }
}

.footer__stores {
  display: flex;
  gap: 1.5em;
  align-items: center;

  span {
    font-weight: 700;
  }

  img {
    height: 2.5em;
  }

  @include media("<lg") {
    display: none;
  }

  @include media("<md") {
    display: flex;
  }
}

.footer__stores--alt {
  display: none;
  order: 1;

  @include media("<lg") {
    display: flex;
    flex-wrap: wrap;
    flex: 0 50%;
    align-content: flex-start;

    span {
      flex: 1 0 100%;
    }
  }

  @include media("<md") {
    display: none;
  }
}

.footer__main {
  padding: 2.125em 0;

  @include media("<md") {
    padding-top: 0;
  }
}

.footer__nav {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
      color: #393c40;
    }
  }

  @include media("<md") {
    padding: 0;
  }
}

.footer__nav-col {
  flex: 0 20%;

  h5 {
    font-size: 20px;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 0.5em;

    @include media("<md") {
      display: none;
      margin-bottom: 0;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  p {
    span {
      display: block;
    }
  }

  button {
    background: none;
    padding: 0;
    cursor: pointer;
  }

  @include media("<lg") {
    flex: 0 25%;
    margin-bottom: 2.5em;
  }

  @include media("<md") {
    flex: 0 100%;
    padding: 0 15px;
    margin-bottom: 0;

    li {
      border-bottom: 1px solid #e7e8e8;
    }

    a {
      font-size: 1.125rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1em 0;
    }
  }
}

.footer__img-list {
  display: flex;
  gap: 0.5em;
  align-items: center;

  img {
    max-height: 55px;
    max-width: none;
  }
}

.footer__payment-methods {
  @include media("<lg") {
    flex: 0 75%;
  }
}

.footer__contact {
  margin-bottom: 0;
  @include media("<lg") {
    flex: 0 50%;
    order: 1;
  }

  address {
    font-style: normal;
  }
}

.footer__copyright {
  margin-left: auto;
  font-size: 0.875em;
  color: #a0a6a6;
  align-self: flex-end;

  @include media("<lg") {
    flex: 0 100%;
    order: 1;
  }
}

.footer__language {
  @include media("<md") {
    padding: 1.5rem 0;
    background-color: #f6f9fa;

    ul {
      display: flex;
    }

    li {
      border: 0;
      flex: 0 50%;

      &:first-child {
        border-right: 1px solid #e7e8e8;
      }
    }

    button {
      padding: 1em 1.5em;
      font-size: 1.125rem;
      text-align: center;
      width: 100%;
      text-transform: uppercase;
      background-color: #fff;

      .long {
        display: none;
      }

      &.active {
        font-weight: 700;
      }
    }
  }

  @include media(">=md") {
    button {
      .short {
        display: none;
      }
    }
  }
}
