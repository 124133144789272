.tell-a-colleague-container {
  display: flex;
  padding: var(--mentalhealth, 0px);
  align-items: flex-start;
  gap: 129px;

  @include media("<xl") {
    gap: 68px;
  }

  @include media("<lg") {
    flex-direction: column;
  }
}

.tell-a-colleague {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 46px;
  width: 65%;

  @include media("<lg") {
    width: 100%;
  }
}

.invite-by-email {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: var(--mentalhealth, 0px);
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;
  flex-wrap: wrap;

  span {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
  }

  div {
    display: flex;
    width: 100%;
    padding: var(--mentalhealth, 0px);
    align-items: flex-start;
    align-content: flex-start;
    gap: 23px;
    flex-wrap: wrap;

    input {
      width: 60%;
      height: 54px;
      border-radius: 27px;
      border: 1px solid #7d7d7d;
      background: #fff;
      padding-left: 29px;
    }

    button {
      font-size: 16px;
      border-radius: 27px;
      border: 1px solid var(--Aqua, #008ca0);
      cursor: pointer;
      background: #008ca0;
      color: #fff;
      font-weight: 700;
      line-height: normal;
      width: 30%;
      text-align: center;
      padding: 15px;
    }
  }

  @include media("<md") {
    div {
      flex-direction: column;
      align-self: stretch;

      input {
        width: 100%;
        height: 54px;
      }

      button {
        width: 50%;
      }
    }
  }
}

.invite-by-link {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  span {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
  }

  div {
    width: 100%;
    height: 54px;
    border-radius: 27px;
    border: 1px solid #7d7d7d;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      color: #ababab;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
      white-space: nowrap;
      overflow: hidden;
      padding-left: 29px;
      width: 80%;
    }

    button {
      color: #000;
      font-size: 16px;
      font-weight: 700;
      line-height: normal;
      border: none;
      background: transparent;
      cursor: pointer;
      white-space: nowrap;
      margin-left: 5px;
      width: unset;
      box-shadow: none;
    }
  }

  @include media("<md") {
    width: 100%;
    height: 54px;

    div {
      width: 100%;
      padding: 16px;

      a {
        width: 70%;
        padding-left: 0;
      }

      button {
        width: 103px;
        margin-right: 15px;

        svg {
          margin-right: 0;
        }
      }
    }
  }
}

.tell-a-colleague--status {
  display: flex;
  width: 30%;
  height: 325px;
  padding: 16px 32px 24px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  border-radius: 8px;
  background: #f0f9fb;

  h6 {
    color: var(--Aqua, #008ca0);
    font-size: 16px;
    font-weight: 700;
    line-height: 150%; /* 24px */
    letter-spacing: 0.48px;
  }

  @include media("<lg") {
    width: 100%;
  }
}
