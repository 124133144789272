/*------------------------------------*\
  Side navigation styles
\*------------------------------------*/

/*
 * Content:
 * Font family Cera Pro
 * Variables
 */

/*------------------------------------*\
  Cera Pro
\*------------------------------------*/

@font-face {
  font-family: "CeraPro";
  src: url("../../fonts/cerapro/Cera-Pro-Regular.eot");
  src:
    url("../../fonts/cerapro/Cera-Pro-Regular.eot") format("embedded-opentype"),
    url("../../fonts/cerapro/Cera-Pro-Regular.woff") format("woff"),
    url("../../fonts/cerapro/Cera-Pro-Regular.woff2") format("woff2"),
    url("../../fonts/cerapro/Cera-Pro-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "CeraPro";
  src: url("../../fonts/cerapro/Cera-Pro-Medium.eot");
  src:
    url("../../fonts/cerapro/Cera-Pro-Medium.eot") format("embedded-opentype"),
    url("../../fonts/cerapro/Cera-Pro-Medium.woff") format("woff"),
    url("../../fonts/cerapro/Cera-Pro-Medium.woff2") format("woff2"),
    url("../../fonts/cerapro/Cera-Pro-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "CeraPro";
  src: url("../../fonts/cerapro/Cera-Pro-Bold.eot");
  src:
    url("../../fonts/cerapro/Cera-Pro-Bold.eot") format("embedded-opentype"),
    url("../../fonts/cerapro/Cera-Pro-Bold.woff") format("woff"),
    url("../../fonts/cerapro/Cera-Pro-Bold.woff2") format("woff2"),
    url("../../fonts/cerapro/Cera-Pro-Bold.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

/*------------------------------------*\
  Variables
  Overwriting nesseccary bootstrap variables
  Font, line-height, and color
  for body text, headings, and more
\*------------------------------------*/

$font-family-sans-serif: "CeraPro", helvetica, arial, sans-serif !default;
