$basic-font-size: 16; // Default

@function em($pixels, $context: $basic-font-size) {
  @return #{calc($pixels / $context)}em;
}

.sc_container {
  padding: 15px;
}

.sc_vertical-center {
  display: flex;
  align-items: center;
}

.sc_justify-space {
  display: flex;
  justify-content: space-between;
}

.sc_justify-center {
  display: flex;
  justify-content: center;
}

// Colors

$sc_primary-color: #34b78f;
$sc_raspberry: #e62e73;
$sc_primary-color-light: lighten($sc_primary-color, 20);
$sc_text-color: #122020;
$sc_light: #aaa;
$sc_border-color: #cbcbcb;
$sc_error-color: #ff6565;
$sc_danger-color: #c83838;
$sc_danger-color-light: #ff6565;

@mixin primary-gradient {
  background: $sc_primary-color;
  background: linear-gradient(0deg, $sc_primary-color 0%, $sc_primary-color-light 100%);
}

@mixin danger-gradient {
  background: $sc_danger-color;
  background: linear-gradient(0deg, $sc_danger-color 0%, $sc_danger-color-light 100%);
}

.sc_primary-color {
  color: $sc_primary-color;
}

// Fonts

@supports (font: -apple-system-body) {
  html {
    font: -apple-system-body !important; // stylelint-disable font-family-no-missing-generic-family-keyword
  }
}

html {
  font-family: "CeraPro", sans-serif !important;
  font-feature-settings: "ss01" 1;
}

.sc {
  line-height: 1.2rem;
  font-size: 1.067rem;
  transition: all 1s linear;

  &.loading {
    min-height: 100vh;
    background-color: #fff;
  }

  .container {
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.4em;
    font-weight: 400;
    margin-bottom: 0.833em;
  }

  h2 {
    font-size: em(24);
  }

  h3 {
    font-size: em(16);
  }

  p {
    line-height: 1.5em;
  }
}

.sc_subtitle {
  font-size: 0.75rem;
  text-align: center;
  text-transform: uppercase;
}

.sc_light {
  color: $sc_light;
}

.sc_uppercase {
  text-transform: uppercase;
}

//Button

.sc_button {
  font-size: 1rem;
  border-radius: 24px;
  background: $sc_primary-color;
  color: #fff;
  font-weight: 700;
  text-align: center;
  padding: 15px 20px;
  display: inline-block;
  margin: 1.5rem 0;
  outline: none;
  cursor: pointer;
  box-shadow: 0 4px 6px 0 rgba(#122020, 0.1);

  &.warning {
    background: $sc_raspberry;
  }

  &.white {
    background: #fff;
    color: $sc_primary-color;
  }

  &.sc_button-inline {
    background: none;
    padding: 0;
    border-radius: 0;
    color: $sc_primary-color;
    box-shadow: none;

    &.warning {
      color: $sc_raspberry;
    }

    &.white {
      color: #fff;
    }
  }

  &.sc_button-full-width {
    width: 100%;
  }
}

.sc_button-neutral {
  background-color: white;
  color: $sc_text-color;
  border: 1px solid #ebebeb;
}

.sc_btn {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
}

.sc_btn-lg {
  padding: 13px 20px;
  font-weight: 500;
  width: 100%;
  display: block;
}

.sc_btn-primary {
  @include primary-gradient;
  color: #fff;
}

.sc_icon {
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;

  svg {
    height: 1em;
    width: auto;
    display: block;
  }
}

.sc_btn-primary-ghost {
  border: 1px solid $sc_primary-color;
  background: transparent;
  color: $sc_primary-color;

  .sc_icon {
    font-size: 0.8em;
  }

  g {
    fill: $sc_primary-color;
  }
}

.sc_btn-white-ghost {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
}

.sc_btn-white {
  background: #fff;
  color: $sc_text-color;
}

.sticky-button-bottom {
  border-radius: 25px 25px 0 0;
  width: 100%;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.sc_help {
  display: inline-block;
  background: #fff;
  vertical-align: middle;
}

//Basic components

.sc_content {
  padding: 30px 0 6rem;
}

.sc_headline {
  font-size: em(16);
  padding: 0 15px;
  text-align: center;
  margin-bottom: 0 !important;
}

.sc_back {
  margin-bottom: 40px;

  span {
    cursor: pointer;
  }
}

//Inputs

.sc_input-group {
  margin-bottom: 25px;
  width: 100%;
}

.sc_input-color-group {
  position: relative;
  padding-right: calc(1.15em + 36px);
}

.sc_input {
  padding: 13px 15px;
  border: 1px solid $sc_border-color;
  display: block;
  width: 100%;
  outline: 0;
  border-radius: 2px;
}

.sc_input-error {
  border-color: $sc_error-color;
}

.sc_input-label {
  font-size: em(10);
  color: $sc_light;
  font-weight: 700;
}

.sc_input-subtitle {
  font-size: em(10);
  color: $sc_light;
  margin-top: 5px;
}

.sc_input-error-msg {
  font-size: em(10);
  color: $sc_error-color;
  margin-top: 5px;
}

.sc_input-color {
  height: calc(1.15em + 26px);
  width: calc(1.15em + 26px);
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 1px;
}

.sc_toggle-switch-label {
  font-size: em(12);
  opacity: 0.3;

  &.active {
    opacity: 1;
  }
}

.sc_toggle-switch {
  display: flex;
  align-items: center;

  input {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    height: 38px;
    width: 57px;
    min-width: 57px;
    background-color: #e8e8e8;
    border-radius: 18px;
    display: block;
    outline: 0;
    cursor: pointer;
    margin: 0 20px;

    &:before {
      content: "";
      position: absolute;
      background-color: #fff;
      border-radius: 50%;
      height: 36px;
      width: 36px;
      left: 1px;
      top: 1px;
      transition: all 0.2s ease-in-out;
      box-shadow: 0 0 12px 0 rgba(#000, 0.2);
      background-image: url("../../images/lock-open.svg");
      background-repeat: no-repeat;
      background-position: 8px 5px;
    }

    &:checked {
      &:before {
        left: 20px;
        background-image: url("../../images/lock-closed.svg");
        background-repeat: no-repeat;
      }
    }
  }
}

.sc_browser {
  background-color: #f7f9fa;
  padding: 20px 0 64px;

  .container {
    max-width: 665px;
  }

  @media only screen and (min-width: 1024px) {
    padding: 70px 0 80px;

    .container {
      display: flex;
      max-width: 1165px;
      padding: 0 15px;
    }
  }
}

#sc_app-frame {
  box-shadow: 0 2px 4px 2px rgba(#122020, 0.1);
  position: relative;
  overflow: hidden;
  background-color: #fff;
  width: 100%;

  .sc_modal {
    position: absolute;
  }

  @media only screen and (min-width: 1024px) {
    border-radius: 8px;
    height: 100%;
    width: 375px;
  }

  .sc_browser & {
    @media only screen and (max-width: 1023px) {
      .sc_modal {
        position: fixed;
      }
    }
  }
}

#sc_app-frame-inner {
  height: 100vh;
  overflow-y: auto;

  .sc_browser & {
    height: 667px;

    @media only screen and (max-width: 1023px) {
      height: auto;
    }
  }
}
