.card-service {
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #bebebe;
  box-shadow: 0 0 10px 0 rgba(#000, 0.12);

  figure {
    margin: 0;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      transition: all 0.12s ease-in-out;
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 400;
    padding: 15px 10px;
    text-align: center;
    margin: 0;
  }

  &:hover {
    figure {
      img {
        transform: scale(1.1);
      }
    }
  }
}
