.links-section {
  padding: 16px 0 61px;
  a:hover {
    text-decoration: underline;
  }
  ul {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    padding: 0;
    margin: 0;
    @include media("<md") {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
    }
    li {
      position: relative;
      padding: 0 0 0 43px;
      list-style-type: none;
      @include media("<md") {
        padding: 0 0 0 34px;
      }
      // &:before
      span {
        position: absolute;
        top: 11px;
        left: 19px;
        display: block;
        width: 5px;
        height: 5px;
        content: "";
        border-radius: 50%;
        background: $color-1;
        @include media("<md") {
          left: 10px;
        }
      }
    }
  }
}
