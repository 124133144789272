/*------------------------------------*\
  Product detail
\*------------------------------------*/

/*
* Visible at:
* all screen sizes
*
* Content:
* container
* overview aside container
*   image
*   list
* table
* tab container
* tab map
* calendar
* Prices & discounts
*   old price
*   comany discount
*   machtfit discount
* button
* STATES: "Discounts"
*   default: no discounts
*   machtfit discount only
*   company discount only
*   both discounts
* STATES: "Merkliste"
*
*/

/*------------------------------------*\
  Container
\*------------------------------------*/

.mf-c_product-detail-container {
  .datepicker {
    // 220 / 14
    margin: 0 auto;
    width: 15.714em;
    @media screen and (min-width: $screen-sm) {
      margin: 0;
    }
  }
  .datepicker,
  th {
    // center month in calendar plugin
    text-align: center;
  }
  th,
  .month,
  .year {
    border-radius: 4px;
    &:hover {
      background-color: $mf-color-gray-light;
    }
  }

  // Alignments for text from the quill rich text editor
  .ql-align-right {
    text-align: right;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-justify {
    text-align: justify;
  }
}

/*------------------------------------*\
  Overview aside container
  * image
  * list
\*------------------------------------*/

.mf-c_product-detail-overview-aside {
  img {
    width: 100%;
    @media screen and (max-width: $screen-sm-min) {
      display: block;
      margin: 0 auto;
      max-width: $product-image-width;
    }
  }
  .list-inline {
    @media screen and (max-width: $screen-sm-min) {
      text-align: center;
    }
    li {
      padding-left: 2px;
      padding-right: 2px;
    }
  }
}

/*------------------------------------*\
  table
\*------------------------------------*/

.mf-c_product-detail-infotable {
  margin-top: $layout-digit * 2;
  table {
    // bootstrap gray
    border-bottom: 1px solid #ddd;
  }
  .dropdown-toggle {
    background-color: var(--base-color);
    border-radius: 4px;
    color: white;
    display: block;
    margin-left: calc($layout-digit / -2);
    margin-right: calc($layout-digit / -2);
    padding: 0.33em 0.75em 0.25em;
  }
  .attachments a {
    color: var(--base-color);
    font-weight: bold;
  }
  .dropdown-menu {
    @include mf-mixin_dropdown-menu;
    border: none;
    width: 100%;
    li {
      a {
        white-space: nowrap;
      }
    }
  }
  .caret {
    margin-top: calc($layout-digit / 2);
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

/*------------------------------------*\
  Calendar
\*------------------------------------*/

.mf-c_product-detail-calendar {
  table {
    width: 100%;
  }
  td {
    border-radius: 100%;
    padding: 0.2em 0.1em 0 0 !important;
    width: 5em;
    height: 2.1em;

    &.old.day,
    &.new.day {
      opacity: 0.4;
    }
  }
  // FIXME
  /* stylelint-disable no-descending-specificity */
  .month,
  .year {
    display: inline-block;
    padding: 1em;
  }
  .today {
    font-weight: bold;
  }
  /* stylelint-enable */
}

.mf-c_product-detail-is-course-date {
  background-color: var(--base-color-dark);
  color: $mf-color-white;
  border-radius: 50%;
  width: 2.1em;
  height: 2.1em;
}

.mf-calendar_export {
  margin-top: 1rem;

  /*
   Hide iCalendar export on mobile app until fixed
   see https://github.com/machtfit/mobile-app-cordova/issues/8
  */
  .mf-mobile_app & {
    display: none;
  }
}

/*------------------------------------*\
  Prices & discounts
  1. old price
  2. comany discount
  3. machtfit discount
\*------------------------------------*/

/* old price */
.mf-c_product-detail-price-old {
  text-decoration: line-through;
}

/* discount from company */
.mf-c_product-detail-price-discount-company {
  border: 1px solid var(--base-color);
  border-radius: 0.75em;
  padding: 0.75em;
  @media screen and (max-width: 1000px) {
    ul.mf-c_product-detail-discount-complete & {
      display: block;
      margin: 5px 0;
      width: 60%;
    }
  }
}

/* discount from machtfit */
.mf-c_product-detail-price-discount-machtfit {
  font-weight: 900;
  span {
    @include mf-price-discount;
  }
}

/*------------------------------------*\
  Discounts
\*------------------------------------*/
.mf-c_product-detail-discount-complete {
  display: block;
  .mf-c_product-detail-price-discount-machtfit {
    display: inline-block;
  }
  .mf-c_product-detail-price-discount-company {
    display: inline-block;
  }
}

.appointment-chooser {
  color: #fff !important;

  .open & {
    color: #999 !important;
  }
}
