/*------------------------------------*\
  Button styles
\*------------------------------------*/

/*
 * Content:
 * Primary button
 * Default button
 * Machtfit 3d button
 * Machtfit colored button
 * Toggle buttons for mobile menu
 *
 */

/*------------------------------------*\
  Primary button
  class should be used in addition,
  when bootstrap class
  .btn-primary is used
\*------------------------------------*/

.mf-c_button-primary {
  background-color: var(--base-color);
  border-color: var(--base-color);
  .dark-theme & {
    color: #333;
  }

  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: var(--base-color-dark);
    border-color: var(--base-color-dark);

    .dark-theme & {
      color: #333;
    }
  }
}

/*------------------------------------*\
  Default button
  class should be used in addition,
  when bootstrap class
  .btn-default is used
\*------------------------------------*/

.mf-c_button-default {
  background-color: $mf-color-gray-light;
  border-color: $mf-color-gray-light;
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: $mf-color-gray-medium;
    border-color: $mf-color-gray-medium;
  }
}

/*------------------------------------*\
  machtfit 3d button
\*------------------------------------*/

.mf-c_button-3d {
  // animating the translate transition
  // can be included, when safari hover bug is fixed
  // so please test in safari
  // transition: transform 0.025s linear, box-shadow 0.025s linear;
  box-shadow: 0 2px 0 0 $mf-color-gray-medium-dark;
  &:hover,
  &:focus,
  &:active,
  &.active {
    box-shadow: none;
    transform: translateY(2px);
  }
}

/*------------------------------------*\
  Mobile menu toggle buttons
  pngs (img element) must have same
  pixel size and white space to look
  good
\*------------------------------------*/

.mf-c_button-primary.mf-recommend-button {
  background-color: #ececec;
  border-color: #ececec;
  font-size: 13px;
  line-height: 23px;
  color: #777;

  &:active,
  &:focus,
  &:hover {
    background-color: darken(#ececec, 10%);
    border-color: darken(#ececec, 10%);
    color: darken(#777, 10%);
    outline: none;
  }
}

.full-width-button {
  @media screen and (max-width: 767px) {
    display: block;
    width: 100%;
    margin-top: 5px;
  }
}

@media screen and (max-width: 475px) {
  .mf-recommend-button,
  #mf-js_button-book {
    display: block;
    width: 100%;
    margin-top: 5px;
  }
}

.thank-you-back-btn {
  margin-right: 20px;
}

.button-container--disabled {
  display: inline;
}

.mf-buttons {
  & > a,
  & > .tool-tip,
  & > button {
    display: inline-block;
    width: 49%;
    vertical-align: bottom;
  }

  & > .mf-c_button-primary:last-child,
  & > .tool-tip + .mf-c_button-primary {
    margin-left: 2%;
  }

  @media screen and (max-width: 475px) {
    & > .mf-c_button-primary:last-child {
      margin-left: 0;
    }

    & > .tool-tip {
      width: 100%;
    }
  }
}
