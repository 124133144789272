.account {
  margin-top: 32px;
  margin-bottom: 32px;

  .tab-bar {
    border-bottom: 1px solid #e1e1e1;

    .container {
      display: flex;
      gap: 20px;

      @include media(">md") {
        gap: 40px;
      }
    }

    a {
      font-weight: 400;
      margin-bottom: -3px;
      cursor: pointer;
      color: #7c8b8d;

      &.active {
        border-bottom: 3px solid #008ca0;
        color: black;
      }
    }
  }

  @include media("<md") {
    > .container {
      padding: 0;
    }
  }

  .panel {
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    margin-top: 32px;
    box-shadow: 0 0 22px #00000038;

    @include media(">md") {
      border-radius: 16px;
      border-left: 1px solid #e1e1e1;
      border-right: 1px solid #e1e1e1;
    }

    .heading {
      display: flex;
      justify-content: space-between;
      padding: 24px 16px 17px 16px;
      width: 100%;
      border-bottom: 1px solid #e1e1e1;
      font-size: 20px;
      line-height: normal;
      margin-bottom: 0;
      min-height: 67px;

      @include media(">md") {
        padding-left: 32px;
        padding-right: 32px;
        flex-direction: row;
      }

      span {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-left: auto;
      }
    }

    .content {
      padding: 40px 16px;

      @include media(">md") {
        padding-left: 32px;
        padding-right: 32px;
      }
    }
  }
}

.bookings {
  header {
    display: flex;
    flex-direction: column;

    @include media(">md") {
      flex-direction: row;
      justify-content: space-between;
    }

    &:not(:first-of-type) {
      margin-top: 48px;
      @include media(">md") {
        margin-top: 96px;
      }
    }

    div {
      display: flex;
      align-items: center;
    }

    h3 {
      margin-right: 6px;

      span {
        font-weight: normal;
      }
    }
  }

  h2 {
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    color: var(--Dark-Night-Black-100, #122020);
  }

  h3 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #122020;
    margin: 0;

    @include media(">md") {
      font-size: 20px;
      line-height: 28px;
    }
  }

  h5 {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #717979;

    @include media(">md") {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .badge {
    display: inline-flex;
    min-width: 24px;
    height: 24px;
    padding: var(--spacing-xxs, 2px) 10px;
    align-items: center;
    border-radius: var(--radius-full, 9999px);
    border: 1px solid var(--Colors-Border-border-brand_subtle, #a9e1d0);
    background: var(--Colors-Background-bg-brand-primary, #ecf9f4);
    color: var(--Colors-Text-text-brand-secondary, #2a9272);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &.list {
    .product {
      display: flex;
      padding: 24px 0;
      border-bottom: 1px solid #cedee7;
      height: 96px;
      box-sizing: content-box;
      cursor: pointer;

      @include media(">md") {
        height: 116px;
      }

      &:last-child {
        border-bottom: none;
      }

      p {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-top: 8px;

        color: var(--Dark-Night-Black-100, #122020);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        span:first-child {
          display: none;

          @include media(">md") {
            display: unset;
          }
        }
      }

      .image {
        margin-right: 16px;
        max-width: 131px;
        display: flex;
        justify-content: center;
        width: 100%;

        @include media(">md") {
          max-width: 162px;
        }
      }

      .infos {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: auto;
        text-align: right;

        .arrow {
          width: 6px;
          height: 12px;
          margin: auto;
          cursor: pointer;

          @include media(">md") {
            display: none;
          }
        }

        span {
          display: none;

          @include media(">md") {
            display: unset;

            &:first-child {
              font-size: 12px;
              line-height: 16px;
              color: #122020;
            }

            &:last-child {
              font-size: 16px;
              line-height: 24px;
              color: #000000;
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  &.detail {
    gap: var(--spacing-spacing-lg, 32px);
    display: grid;

    @include media(">md") {
      grid-template-columns: minmax(auto, 358px) 1fr 1fr 1fr;
    }

    img {
      max-height: 262px;
      width: 100%;
      object-fit: cover;

      @include media(">md") {
        max-width: 358px;
        grid-row: 1/3;
      }
    }

    ul {
      margin: 16px 0 0 0;
      padding: 0;
      display: flex;
      gap: 16px;
      flex-direction: column;

      &:first-of-type {
        margin-bottom: 32px;
      }
    }
    li {
      list-style: none;

      color: var(--Dark-Night-Black-100, #122020);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    h1 {
      color: var(--Dark-Night-Black-100, #122020);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;

      @include media(">md") {
        font-size: 26px;
      }
    }

    h3 {
      color: var(--Dark-Night-Black-100, #122020);

      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 16px;

      &.light {
        color: var(--Dark-Night-Black-60, #717979);
        font-weight: 400;
      }
    }

    a {
      text-decoration: underline;
    }

    p {
      margin: 0;
    }

    a,
    button {
      color: var(--Dark-Night-Black-100, #122020);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      text-decoration-line: underline;
      background: none;
      display: flex;
      padding: 0;
      cursor: pointer;

      &.success {
        background-image: url(../../../images/checkmark.svg);
        background-repeat: no-repeat;
        background-position: left;
        padding-left: 25px;
      }
    }

    footer {
      display: flex;
      justify-content: right;
      flex-direction: column;
      border-top: 1px solid #cedee7;
      padding-top: 40px;
      gap: var(--spacing-spacing-md, 24px);

      @include media(">md") {
        flex-direction: row;
        gap: 40px;
        grid-column: 1/ -1;
      }
    }

    section {
      display: flex;
      flex-direction: column;

      &.price,
      &.product {
        @include media(">md") {
          border-bottom: 1px solid #cedee7;
          padding-bottom: 32px;
        }
      }

      &.product {
        @include media(">md") {
          grid-column: 2/4;
        }
      }

      &.price {
        @include media(">md") {
          text-align: right;
          margin-left: -44px;
        }
      }

      &.contact {
        gap: 8px;

        @include media(">md") {
          grid-row: 3;
        }
      }

      &.access-code {
        grid-row: 2/10;
        p {
          padding: 0px 46px 0 16px;
          text-align: center;
          border-radius: 14px;
          background: #ebf5f2;
          color: var(--Dark-Night-Black-100, #122020);
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 4px;
          cursor: pointer;

          background-image: url(../../../images/copy.svg);
          background-repeat: no-repeat;
          background-position: center right 16px;

          &.active {
            background-image: url(../../../images/checkmark.svg);
          }
        }

        div.barcode {
          margin-top: 16px;
        }
      }

      &.appointments,
      &.instructions {
        @include media(">md") {
          grid-row: 2/10;
        }
      }

      &.appointments a {
        display: flex;
        align-items: center;
        gap: 6px;
        color: var(--Dark-Night-Black-100, #122020);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 16px;
        text-decoration: none;
      }

      &.instructions {
        border-radius: 8px;
        background: #f0f9fb;
        padding: var(--spacing-spacing-md, 24px) 16px;

        @include media(">md") {
          grid-column-start: 2;
          grid-column-end: -1;
        }

        h3 {
          color: var(--machtfit-Green-100, #34b78f);
          margin-bottom: 8px;
        }
      }

      &.appointments ~ .instructions,
      &.access-code ~ .instructions {
        @include media(">md") {
          grid-column-start: 3;
        }
      }
    }
  }
}
