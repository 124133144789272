.sc_alert-bar {
  background: #f8f9fa;
  padding: 5px 1rem;

  button {
    background-color: transparent;
  }

  .sc_btn {
    flex: none;
    margin-left: 10px;
    font-size: em(14);

    i {
      font-size: 1.5em;
    }
  }
}

.sc_alert-bar-message {
  font-size: 0.75rem;
}
