/*------------------------------------*\
  Project specific variables
\*------------------------------------*/

/*
 * Content:
 *
 * SETTINGS
 * white
 * gray
 * black
 * colors
 *
 * MATCHING
 * standard colors
 * typographic colors
 * layout element colors
 *
 */

/*------------------------------------*\
  COLOR SETTINGS
\*------------------------------------*/

/*------------------------------------*\
  Theme specific variables
\*------------------------------------*/

:root {
  --base-color: #488cff;
  --base-color-dark: #488cff;
  --base-color-light: #488cff;
  --base-color-lighter: #488cff;
}

// Used instead $link-color and $link-hover-color
$mf-color-link-hover: var(--base-color);
$mf-color-link-visible: var(--base-color);

$mf-color-white: #fff;

// #f7f7f7
$mf-color-white-dark: darken($mf-color-white, 3);
$mf-color-white-dark-semi-transparent: rgba($mf-color-white-dark, 0.9);

// #ededed
$mf-color-white-darker: darken($mf-color-white, 7);
$mf-color-gray-standard: #7e7e7e;

// #e6e6e6
$mf-color-gray-light: lighten($mf-color-gray-standard, 40);
$mf-color-gray-light-transparent: rgba(126, 126, 126, 0.5);

// #d6d6d6
$mf-color-gray-medium: lighten($mf-color-gray-standard, 34);

// #a1a1a1
$mf-color-gray-medium-dark: lighten($mf-color-gray-standard, 13);
$mf-color-black: #000;
$mf-color-gray-blue: #5e646d;

// #363a3f
$mf-color-gray-blue-dark: darken($mf-color-gray-blue, 16);
$mf-color-machtfit: #34b78f;
$mf-color-machtfit-dark: darken($mf-color-machtfit, 10);
$mf-color-facebook: #3b5998;
$mf-color-twitter: #55acee;

$mf-color-error: #f12800;

/*------------------------------------*\
  Typographic colors
\*------------------------------------*/

// Used instead $text-color
$mf-color-text-default: $mf-color-gray-blue-dark;

// Used instead $link-color and $link-hover-color
$mf-color-link: $mf-color-gray-blue;
$mf-color-link-hover: var(--base-color);
$mf-color-link-visible: var(--base-color);
