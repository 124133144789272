.my-activity {
  display: flex;
  justify-content: space-between;
  gap: 100px;

  @include media("<md") {
    flex-direction: column;
    gap: 70px;
  }
}

.activity-section {
  gap: 39px;
  flex-direction: column;
  display: flex;
  h2 {
    color: #000;
    font-family: CeraPro;
    font-feature-settings: "ss01" 1;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.48px;
  }
}

.badges {
  display: flex;
  gap: 40px;
  max-width: 700px;
  flex-wrap: wrap;
  @include media("<md") {
    justify-content: space-evenly;
  }

  .badge {
    display: flex;
    flex-direction: column;
    filter: grayscale(1);
    opacity: 0.4;
    width: 140px;
    align-items: center;
    position: relative;
    cursor: pointer;

    &.obtained {
      filter: grayscale(0);
      opacity: 1;
    }

    div {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
      justify-content: center;
      align-items: center;
      display: flex;

      &:hover {
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.25);
        transition: box-shadow 0.1s ease-in-out;
      }
    }

    span {
      color: #000;
      font-family: CeraPro;
      font-feature-settings: "ss01" 1;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.48px;
      margin-top: 12px;
      text-align: center;
    }
  }
}

.badge-popup {
  img {
    width: 110px;
    height: 110px;
  }

  a {
    min-width: 254px;
    padding: 8px 24px 8px 24px;
    border-radius: 360px;
    border: 1px;
    gap: 8px;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid #122020;
    margin-bottom: 32px;
    text-align: center;
  }

  p {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin: 0;

    &.description {
      padding-left: 16px;
      padding-right: 16px;
    }

    &.obtention-rate {
      margin-bottom: 32px;
      margin-top: 12px;
      display: flex;
      padding: 0 16px;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.36px;
    }

    &.obtained {
      background: var(--machtfit-Green, #34b38e);
      display: flex;
      width: 521px;
      height: 54px;
      padding: 6px 184px;
      justify-content: center;
      align-items: center;
      color: #fff;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.36px;
    }
  }
}

.challenge-item {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 34px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #d4d4d4;
  background: #fff;
  width: 468px;

  img {
    width: 48px;
    height: 48px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 9px;

    div {
      flex-direction: row;
      align-items: center;

      img {
        width: 18px;
        height: 18px;
      }
    }
  }

  @include media("<md") {
    gap: 27px;
    width: 100%;

    div {
      gap: 4px;
    }
  }
}

.active-challenge-item {
  border: 1px solid var(--Aqua, #008ca0);
  background: #f0f9fb;

  img {
    width: 55px;
    height: 55px;
  }

  div {
    gap: 8px;

    div {
      gap: 4px;
      flex-direction: column;
      align-items: flex-start;

      h2 {
        color: var(--Aqua, #008ca0);
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.48px;
      }
    }
  }
}

.challenges {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.challenge-description {
  color: #000;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.48px;
}

.challenge-status {
  color: #000;
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0.36px;
}

.challenge-action {
  padding: var(--spacing-spacing-sm, 8px) var(--spacing-spacing-md, 24px);
  border-radius: var(--radius-radius-full, 360px);
  background: var(--surface-surface-brand, #34b78f);
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px; /* 150% */
  cursor: pointer;
}
