.health-goals {
  border-bottom: 1px solid #d5d5d5;
  padding: 16px 0;
  overflow-y: hidden;
  scroll-snap-stop: always;
  scroll-snap-type: x mandatory;

  div {
    flex-wrap: nowrap;
    display: flex;
    line-height: 1.2em;
    padding: 0;
  }

  button {
    background: none;
    border: 0;
    outline: 0;
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    opacity: 0.3;
    transition: all ease-in-out 0.2s;

    @media only screen and (min-width: 1200px) {
      flex: 0 0 14.285%;
      max-width: 14.285%;
      font-size: 16px;
    }

    @media only screen and (max-width: 1200px) {
      flex: 0 0 22.2%;
      max-width: 22.2%;
    }

    @media only screen and (max-width: 700px) {
      flex: 0 0 40%;
      max-width: 40%;
      font-size: 12px;
    }

    &.active {
      opacity: 1;
    }

    img {
      height: 36px;
      margin-bottom: 8px;
      transition: all ease-in-out 0.2s;
    }
  }
}
