/*------------------------------------*\
  Product thumbnail
\*------------------------------------*/

/*
* Visible at:
* all screen sizes
*
* Content:
* Default state
*   complete thumbnail
*   columns
*   image container
*   title
*   location
*   price
*   product type
*   date
*   glyphicons
*   overwrites
* Different thumbnail states
*   AppView
* Empty list
* Expired offers
*/

/*------------------------------------*\
  Complete thumbnail
\*------------------------------------*/

.mf-c_product-thumbnail {
  background-color: $mf-color-white;
  border-bottom: 1px solid $mf-color-gray-light;
  @include mf-box-shadow;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  margin-bottom: 15px;
  padding: 1em 0;
  // 236 / 14
  height: 9.214em;
  @media screen and (min-width: $screen-sm) {
    border: 1px solid white;
    padding: $layout-digit 0;
    // 236 / 14
    height: 16.857em;
    &:hover,
    &:active {
      border: 1px solid var(--base-color-light);
    }
  }
  @media screen and (max-width: $screen-sm) {
    .mf-c_product-thumbnail-column1 > img {
      width: 100%;
      max-width: 185px;
    }

    /*
     * Vertical alignment of img,
     * see http://stackoverflow.com/questions/18516317
     */
    .mf-c_product-thumbnail-column1 {
      text-align: center;
      // FIXME
      /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
      font: 0/0 a;
    }

    .mf-c_product-thumbnail-column1:before {
      content: " ";
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }
  }

  /*
   * Add some vertical space and move
   * thumbnail higher to not collide
   * with second line of price field.
   * FIXME: Replace hardcoded values
   */
  @media screen and (min-width: 312px) and (max-width: 380px) {
    height: 9em;

    .mf-c_product-thumbnail-location {
      bottom: 2px;
    }
  }

  .listing-hover {
    color: $mf-color-text-default;
  }

  &--promoted-badge {
    position: absolute;
    right: 75px;
    top: 15px;
    width: 115px;
    border: 2px solid;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    color: var(--base-color);
    font-weight: bold;

    @media screen and (max-width: $screen-md) {
      border: none;
      width: 20px;
      right: 29px;
      top: 20px;

      span:last-child {
        display: none;
      }
    }

    > .glyphicon.glyphicon-star {
      color: inherit;
      margin-right: 5px;
      vertical-align: top;
    }
  }
}

/*------------------------------------*\
  Columns
\*------------------------------------*/

.mf-c_product-thumbnail-column1,
.mf-c_product-thumbnail-column2 {
  height: 100%;
}

.mf-c_product-thumbnail-column2 {
  padding: 0;
  @media screen and (min-width: $screen-sm) {
    padding: 0 $layout-digit;
  }
}

/*------------------------------------*\
  Image container
\*------------------------------------*/

// FIXME
/* stylelint-disable-next-line no-duplicate-selectors */
.mf-c_product-thumbnail {
  .mf-c_product-thumbnail-column1 {
    overflow: hidden;
  }
  .content-sidebar {
    width: 100%;

    .no-decoration {
      margin: 0 1%;
      width: 31%;
    }
  }
}

/*------------------------------------*\
  Title
\*------------------------------------*/

.mf-c_product-thumbnail-title {
  // 22 / 14
  font-size: 1em;
  // 28 / 22
  line-height: 1.3em;
  margin-top: 0;
  @media screen and (min-width: $screen-md) {
    // 22 / 14
    font-size: 1.571em;
  }
  small {
    color: $mf-color-text-default;
    display: block;
    font-size: 1em;
    padding-bottom: calc($layout-digit / 4);
    overflow: hidden;
    text-overflow: ellipsis;
    // reset uppercase
    text-transform: none;
    white-space: nowrap;
    @media screen and (min-width: $screen-md) {
      // 16 / 22
      font-size: 0.727em;
    }
  }
}

.mf-c_product-thumbnail-title-big {
  display: block;
  // cut after two lines
  overflow: hidden;
  // IE8
  height: auto !important;
  // line height *  line number
  height: 1.3em * 2;
  // IE8
  max-height: 1.3em * 2;
}

/*------------------------------------*\
  Location
\*------------------------------------*/

.mf-c_product-thumbnail-location {
  position: absolute;
  bottom: 0;
  p {
    margin: 0;
  }
}

/*------------------------------------*\
  Glyphicons
\*------------------------------------*/

// FIXME
/* stylelint-disable-next-line no-duplicate-selectors */
.mf-c_product-thumbnail {
  .glyphicon {
    color: $mf-color-gray-medium-dark;
  }
  .glyphicon-chevron-right {
    top: 3.071em;
    right: calc($layout-digit / 2);
  }
}

/*------------------------------------*\
  STATE: default flag and other default
         styles
\*------------------------------------*/

.mf-c_product-thumbnail-state {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  img {
    padding: 0;
    margin: 0;
    border: 0;
    width: 50%;
  }
}

/*------------------------------------*\
  STATE: machtfit discount
  * hidden styles
  * visible state
\*------------------------------------*/

.mf-c_product-thumbnail-price-discount-machtfit {
  @include mf-price-discount;
  display: none;
}

/*------------------------------------*\
  App
\*------------------------------------*/

/*------------------------------------*\
  Expired List
\*------------------------------------*/
