.items-grid {
  padding: 17px 0 0;
  @include media("<md") {
    padding: 40px 0 0;
    + .items-grid {
      padding: 20px 0 0;
    }
  }
  &:last-of-type {
    padding-bottom: 60px;
    @include media("<md") {
      padding-bottom: 0;
    }
  }
  .container {
    width: 100%;
  }
  .row {
    display: flex;
    margin: 0 -11px -16px;
    flex-wrap: wrap;
    @include media("<lg") {
      margin: 0 -8px;
    }
  }
}

.panels-grid {
  padding: 33px 0 0;
  @include media("<md") {
    padding: 0;
  }
}

.section-heading {
  padding-top: 44px;
  @include cf();
  @include media("<md") {
    padding-top: 15px;
    text-align: center;
  }
  &-link {
    line-height: 34px;
    float: right;
  }
}

.popup-trigger {
  position: relative;
  z-index: 7;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
  text-align: left;
  outline: 0;
  background: transparent;
}

.popup-item {
  &-wrapper {
    position: absolute;
    z-index: 99;
    top: 50%;
    right: 0;
    left: 0;
    min-width: 100%;
    display: none;
    padding: 10px 0;
    background: white;
    border-radius: 8px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3);
    &.active {
      display: block;
    }
  }
  &-close {
    position: absolute;
    top: 15px;
    right: 15px;
    display: block;
    width: 14px;
    height: 14px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border: 0;
    background: transparent;
    box-shadow: none;
    outline: 0;
    &:before,
    &:after {
      position: absolute;
      top: 6px;
      left: -2px;
      display: block;
      width: 16px;
      height: 1px;
      content: "";
      opacity: 1;
      background: $color-black;
    }
    &:after {
      transform: rotate(-45deg);
    }
    &:before {
      transform: rotate(45deg);
    }
  }
  &-content {
    max-width: 100%;
    padding: 20px 0;
    background: #fff;
    @include media("<md") {
      border-radius: 10px;
    }
    .single-day {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }

      ul {
        padding: 0;
        list-style: none;
      }
    }
    li {
      a {
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        padding: 10px 20px 10px 60px;
        transition: all 0.05s ease-in-out;
        @include media("<md") {
          padding: 4px 20px 4px 30px;
        }
        &:hover {
          box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.2);
        }
      }
    }
    .single-day + .single-day {
      position: relative;
      padding-top: 10px;
    }
  }
}

.single-day-caption {
  line-height: 24px;
  position: relative;
  margin: 0 0 6px;
  padding-left: 57px;
  font-weight: 700;
  font-size: 16px;
  &:before {
    position: absolute;
    position: absolute;
    top: 3px;
    left: 30px;
    display: block;
    width: 19px;
    height: 17px;
    content: "";
    background-image: url("../../../images/calendar.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.booked-out {
  font-size: 0.7em;
  font-weight: 700;
  color: #fd5656;
}
