.bgm-info-container {
  display: flex;
  width: 100%;
  max-width: 1520px;
  padding-top: var(--spacing-md, 16px);
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-sm, 8px);
  align-self: stretch;

  @include media(">md") {
    padding: 0;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: var(--spacing-lg, 24px);
  }
}

.bgm-info {
  display: flex;
  padding: var(--spacing-md, 16px);
  align-items: flex-start;
  gap: var(--spacing-sm, 8px);
  align-self: stretch;
  border-radius: var(--radius-xl, 12px);
  border: 1px solid var(--Colors-Border-border-primary, #d0d5dd);
  background: var(--Colors-Background-bg-primary, #fff);

  @include media(">md") {
    padding: var(--spacing-xl, 16px);
    gap: var(--spacing-xl, 16px);
    flex: 1 0 0;
  }
}

.bgm-info-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.bgm-info-icon {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-full, 9999px);
  background: var(--Colors-Background-bg-brand-primary, #ecf9f4);
}

.bgm-info-header {
  color: var(--Colors-Text-text-primary, #1a1a1a);

  /* Text md/Bold */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}

.bgm-info-action-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 0 0 auto;
}

.bgm-info-action-btn {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
}

.bgm-info-content {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xs, 4px);
  flex: 1 0 0;
  align-self: stretch;

  @include media(">md") {
    align-items: flex-start;
  }
}

.bgm-info-description {
  display: none;
  color: var(--Colors-Text-text-secondary, #4d4d4d);

  /* Text md/Regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */

  @include media(">md") {
    display: block;
  }
}
