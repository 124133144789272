/*------------------------------------*\
  FAQ
\*------------------------------------*/

/*
* Visible at:
* every screen-size
*
* Content:
* FAQ accordeon
*   container
*   toggle icon
*   open status
*/

/*------------------------------------*\
  FAQ accordeon
\*------------------------------------*/

.mf-c_faq-container {
  margin-left: calc($layout-digit / -2);
  .panel-default,
  .panel-heading,
  .panel-group,
  .panel-body {
    background-color: transparent;
    border: none;
    border-top: none !important;
    box-shadow: none;
    padding: calc($layout-digit / 4) 0;
  }
  .glyphicon-chevron-down {
    padding: 0 calc($layout-digit / 2);
    transition: transform 0.25s;
    transform: rotate(-90deg);
  }
  .panel-heading {
    margin-left: calc($layout-digit * 2);
    .glyphicon-chevron-down {
      margin-left: calc($layout-digit * -2);
    }
  }
  .panel-collapse {
    padding-left: calc($layout-digit * 2);
  }
  .mf-c_faq-opened {
    .panel-heading {
      color: var(--base-color);
    }
    .glyphicon-chevron-down {
      transform: rotate(0);
    }
  }
  .panel-body a {
    text-decoration: underline;
  }
}
