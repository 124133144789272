/*------------------------------------*\
  Project specific mixins
\*------------------------------------*/

/*
 * Content:
 * Price discount
 * Box shadow
 * Dropdown menu
 *
 */

/*------------------------------------*\
  Price discount
\*------------------------------------*/

@mixin mf-price-discount($font-size: 14px) {
  background-color: $mf-color-machtfit;
  border-radius: 100%;
  color: $mf-color-white;
  display: inline-block;
  font-size: $font-size * 0.75;
  width: 4em;
  height: 4em;
  text-align: center;
  vertical-align: middle;
  line-height: 4em;
}

/*------------------------------------*\
  Box shadow
\*------------------------------------*/

@mixin mf-box-shadow {
  box-shadow: 1px 2px 1px 1px $mf-color-gray-light;
}

/*------------------------------------*\
  Tabs
\*------------------------------------*/

@mixin mf-tabs {
  .tab-content {
    background-color: $mf-color-white;
    padding: $layout-digit * 2;
    @media screen and (min-width: $screen-sm) {
      // bootrap-gray
      border-left: 1px solid #ddd;
      // bootrap-gray
      border-right: 1px solid #ddd;
      // bootrap-gray
      border-bottom: 1px solid #ddd;
    }
  }
}

/*------------------------------------*\
  Dropdown specific mixin
  ------------------------------------
  A mixin for machtfit-dropdown menus
  Has to be executed on the element
  above the <ul></ul> element, which
  holds the dropdown toggle button
  and the dropdown menu list
  ------------------------------------
  Elements and classes that are
  effected, when inside the mixin
  container:

  <li></li>
  <a></a>
  a:hover
  a:active
  .active
  .divider
  .dropdown-header
\*------------------------------------*/

@mixin mf-mixin_dropdown-menu($margin-top: 0, $width: auto) {
  border: none;
  border-radius: 2px;
  margin-top: $margin-top;
  padding: calc($layout-digit / 2);
  width: $width;
  li {
    a {
      padding: calc($layout-digit / 4) calc($layout-digit / 2);
      white-space: normal;
      &:hover,
      &:active {
        background-color: transparent;
        color: var(--base-color);
      }
    }
    &.active {
      a,
      a:hover,
      a:active {
        background-color: transparent;
        color: var(--base-color);
      }
    }
  }
  .divider {
    margin-top: calc($layout-digit / 3);
    margin-bottom: calc($layout-digit / 3);
  }
  .dropdown-header {
    padding: 3px 20px 8px 8px;
  }
}
