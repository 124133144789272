.pagination {
  list-style: none;
  line-height: 1em;
  padding: 0;
  text-align: center;

  li {
    display: inline-block;
    margin-right: 15px;

    &.prev {
      margin-right: 20px;
    }

    &.next {
      margin-left: 20px;
    }
  }

  button {
    padding: 0;
    outline: 0;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
