.pace {
  user-select: none;
  height: 5px;
}

.pace .pace-progress {
  background: var(--base-color);
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 5px;
}

.pace .pace-progress-inner {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow:
    0 0 10px var(--base-color),
    0 0 5px var(--base-color);
  opacity: 1;
  transform: rotate(3deg) translate(0, -4px);
}

.pace .pace-activity {
  display: block;
  position: fixed;
  z-index: 2000;
  top: 15px;
  right: 15px;
  width: 14px;
  height: 14px;
  border: solid 2px transparent;
  border-top-color: var(--base-color);
  border-left-color: var(--base-color);
  border-radius: 10px;
  animation: pace-spinner 400ms linear infinite;
}

.pace .pace-activity-inline {
  display: inline-block;
  z-index: 2000;
  width: 14px;
  height: 14px;
  border: solid 2px transparent;
  border-top-color: var(--base-color);
  border-left-color: var(--base-color);
  border-radius: 10px;
  animation: pace-spinner 400ms linear infinite;
}

.pace-inactive .pace-activity,
.pace-inactive .pace-activity-inline {
  display: none;
}

@-webkit-keyframes pace-spinner {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes pace-spinner {
  0% {
    -moz-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes pace-spinner {
  0% {
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes pace-spinner {
  0% {
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes pace-spinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
