.sc_modal {
  position: fixed;
  display: flex;
  z-index: 20;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(18, 32, 32, 0.9);
  animation-name: overlayIn;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;

  &.closing {
    animation-name: overlayOut;

    .content {
      animation-name: slideOut;
    }
  }

  .content {
    align-self: flex-end;
    position: relative;
    background-color: #fff;
    margin: 0 auto;
    padding: 2.313rem 1rem;
    border-radius: 25px 25px 0 0;
    width: 100%;
    box-shadow:
      0 4px 8px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-size: 0.875rem;
    text-align: center;
    position: relative;
    max-height: 100%;
    overflow-y: scroll;
    animation-name: slideIn;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;

    &.branded-bg {
      background-image: url("../../images/branded-bg.svg");
      background-size: cover;
      color: #fff;

      h3 {
        font-size: 1.75rem;
        padding-right: 20%;
      }

      p {
        padding-right: 20%;
      }
    }

    &.left-aligned {
      text-align: left;
    }

    &.full-screen {
      height: 100%;
      padding-top: 20%;
      border-radius: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .sc_modal-button-group {
        margin-top: 45px;
      }

      img {
        align-self: flex-start;
      }
    }
  }

  img {
    margin-bottom: 2rem;
  }

  h3 {
    font-size: 2.25rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  .sc_button {
    width: 100%;
  }

  .sc_modal_team-members {
    font-size: 1rem;
    text-align: left;
    padding: 20px 0;
  }
}

.sc_modal-close {
  background: none;
  outline: 0;
  position: absolute;
  top: 1.25rem;
  right: 1rem;
  font-size: 22px;
  padding: 0;
  cursor: pointer;

  i {
    margin: 0;
  }
}

.sc_modal-help {
  position: absolute;
  top: 1.25rem;
  left: 1rem;
  font-size: 22px;

  i {
    margin: 0;
  }
}

@keyframes overlayIn {
  from {
    background-color: rgba(18, 32, 32, 0);
  }

  to {
    background-color: rgba(18, 32, 32, 0.9);
  }
}

@keyframes overlayOut {
  from {
    background-color: rgba(18, 32, 32, 0.9);
  }

  to {
    background-color: rgba(18, 32, 32, 0);
  }
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%);
  }
}
