.sc_details {
  padding: 0 15px;

  @media only screen and (min-width: 1024px) {
    padding: 0 40px 0 0;
    width: calc(100% - 375px);
  }

  .sc_introduction {
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  h1 {
    font-size: 2.25rem;
    max-width: 466px;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 4px;
  }

  p {
    max-width: 466px;
    margin-bottom: 16px;
    font-size: 1rem;
  }
}

.sc_stats {
  margin-top: 45px;
  max-width: 570px;

  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.sc_stats-item {
  text-align: center;
  display: inline-block;
  margin-bottom: 48px;
  width: 50%;
  font-size: 1rem;
  vertical-align: top;

  @media only screen and (min-width: 768px) {
    width: 33.333%;
  }

  span {
    display: block;
    font-size: 1.5rem;
    font-weight: 700;
    color: $sc_primary_color;
    margin-bottom: 14px;
  }
}

.sc_app-advert {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;

  p {
    max-width: 310px;
    margin: 0 0 34px;
    padding-top: 10px;
  }

  @media only screen and (max-width: 767px) {
    align-items: center;
    text-align: center;
  }

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    align-items: flex-end;

    p {
      margin: 0;
    }
  }

  @media only screen and (min-width: 1024px) {
    align-items: flex-start;
    margin-top: 80px;
  }
}

.sc_app-advert-qr {
  img {
    margin: 0 20px;
  }

  &:first-child {
    img {
      margin-left: 0;
      margin-right: 30px;
    }
  }

  @media only screen and (max-width: 1023px) {
    display: none;
  }
}

.sc_app-advert-logos {
  a {
    display: inline-block;
    margin: 0 15px 15px 0;

    &:last-child {
      margin-right: 0;
    }
  }

  img {
    height: 43px;
  }

  @media only screen and (min-width: 768px) {
    a {
      margin: 0 10px;
    }
  }

  @media only screen and (min-width: 1024px) {
    display: none;
  }
}
