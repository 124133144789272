/*------------------------------------*\
  Login styles
\*------------------------------------*/

/*
* Visible at:
* every screen-size
*
* Content:
* Login
* Register
* Logout
* Imprint
*
*/

/*------------------------------------*\
  Login container
\*------------------------------------*/

.mf-c_login-container {
  .mf-l_container-content-gray {
    padding: $layout-digit * 2 0;
  }
  input,
  select,
  .btn {
    margin-bottom: $layout-digit;
  }
  .mf-typo_color-white {
    @media screen and (min-width: $screen-md) {
      margin-left: 8.3333333333%;
    }
  }

  .tooltip-inner {
    text-align: left;
    max-width: 250px;
  }

  .company-logo {
    color: transparent;
    line-height: 50px;
    height: 50px;
    width: 288px;
    margin-left: 20px;
  }
}

/*------------------------------------*\
  Register container
\*------------------------------------*/

.mf-c_login-register {
  margin-top: $layout-digit * 3;
}

/*------------------------------------*\
  Logout container
\*------------------------------------*/

.mf-c_login-container-logout {
  .mf-l_container-content-gray {
    padding-top: $layout-digit * 4;
    padding-bottom: $layout-digit * 3;
  }
}
