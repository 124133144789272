.notification-2019 {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.67);
  z-index: 3000;
}

.notification__wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  background: white;
  border-radius: 6px;
  padding: 30px;
  width: 100%;
  max-width: 870px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.4);
  overflow-y: auto;
  max-height: 100%;
}

.notification__content {
  a {
    text-decoration: underline;
  }

  img {
    margin: 30px 0;
    max-width: 100%;
  }

  iframe {
    margin: 30px 0;
    max-width: 100%;
  }
}

.notification__bottom {
  margin-top: 40px;
  text-align: right;
}
