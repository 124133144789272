.health-profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 64px;
  width: 100%;

  @include media(">xl") {
    width: 1301px;
  }

  @include media("<md") {
    gap: 33px;
    width: 100%;
  }
}

.health-profile__status-container {
  display: flex;
  align-items: center;
  gap: 64px;

  @include media("<xxl") {
    flex-wrap: wrap;
  }

  @include media("<md") {
    width: 356px;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
  }
}

.health-profile__status {
  display: flex;
  width: 394px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  font-size: 12px;

  @include media("<md") {
    width: 328px;
  }
}

.health-profile__title {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.48px;
}

.health-profile__status-scale {
  display: flex;
  align-items: flex-start;
  gap: 21px;

  div {
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
    border-radius: 50%;

    img {
      width: 37px;
      height: 37px;
    }
  }

  .health-profile__status-scale--active {
    background-color: var(--Aqua, #008ca0);
  }
}

.health-profile__section-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 40px;

  @include media("<md") {
    width: 356px;
  }
}

.health-profile__section {
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: center;

  @include media("<md") {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
}

.health-profile__button {
  color: var(--Aqua, #008ca0);
  font-size: 16px;
  border-radius: 27px;
  border: 1px solid var(--Aqua, #008ca0);
  background: #fff;
  padding: 7px 40px;
  cursor: pointer;
}

.health-profile__info-container {
  display: flex;
  align-items: center;
  gap: 64px;

  @include media("<lg") {
    flex-wrap: wrap;
  }

  button {
    width: 276px;
    background: #fff;
    color: #161616;
    border: 1px solid #000;
    flex-shrink: 0;
  }

  @include media("<md") {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 33px;

    button {
      width: 100%;
    }
  }
}

.health-profile__info-box {
  display: flex;
  padding: 16px 32px 24px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
  border-radius: 8px;
  background: #f0f9fb;

  button {
    background: #008ca0;
    color: #fff;
    border: 0px;
  }

  @include media("<md") {
    padding: 16px 16px 24px 16px;
  }
}

.health-profile__info-title {
  color: var(--Aqua, #008ca0);
  font-size: 16px;
  font-weight: 700;
}

.health-profile__info-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  span {
    color: #000;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.48px;
  }
}

.health-profile__info-action {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 15px 78px;
  line-height: normal;
  border-radius: 27px;
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
  cursor: pointer;

  @include media("<md") {
    width: 324px;
    gap: 10px;
  }
}
