.product-map-container {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.product-map {
  height: calc(100vh - 400px);
  width: 100%;
  min-height: 300px;
}

.map-product-overlap {
  position: absolute;
  height: 100%;
  width: 450px;
  top: 0;
  z-index: 1;
  left: -450px;
  transition: left 0.2s ease-out;
  background-color: white;
  display: flex;
  flex-direction: column;

  @include media("<md") {
    height: auto;
    width: 100%;
    left: 0;
    position: relative;
    margin-top: 8px;
    visibility: hidden;
  }

  &.active {
    left: 0;
    @include media("<md") {
      visibility: inherit;
    }
  }

  .map-product-list {
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    @include media(">md") {
      margin-right: 8px;
    }
  }

  .close {
    z-index: 2;
    cursor: pointer;
    width: 54px;
    height: 54px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../../images/close-2023.svg");
    margin-left: auto;
    @include media("<md") {
      display: none;
    }
  }

  p {
    font-size: 20px;
    margin: 0;
    padding: 0;
  }

  a {
    background-color: white;
    font-size: 15px;
    display: flex;
    border: 1px solid #cecece;
    border-radius: 4px;
  }
}

.map-popup {
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 16px;
  border: 1px solid #bfbfbf;
  box-shadow: 0 0 22px #00000038;
  max-width: 600px;
  padding: 30px;
  text-align: center;
  background-color: white;
  z-index: 1000;
  transform: translate(-50%, -50%);
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 20px;
    margin: 0;
    padding: 0;
  }

  button {
    display: inline-block;
    background-color: #008ca0;
    color: #fff;
    border-radius: 27px;
    cursor: pointer;
    font-family: CeraPro;
    font-feature-settings: "ss01" 1;
    font-size: 16px;
    height: 44px;
    padding: 0;
    width: 280px;
    font-weight: 700;
  }

  a {
    font-size: 15px;
    text-decoration: underline;
    display: block;
  }
}
