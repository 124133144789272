.sc_nav-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 0px 48px 0 rgba(112, 144, 176, 0.2);
  background: #fff;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  padding: 10px;

  nav {
    list-style: none;
    display: flex;
    justify-content: space-around;
  }

  .sc_icon {
    font-size: 1.5em;
    margin: 0;
  }

  a {
    display: block;
    text-align: center;
    padding: 8px 8px 3px;

    span {
      display: block;
      font-size: 0.688rem;
    }
  }

  a.active {
    color: $sc_primary-color;
    border: 0;
    font-weight: 700;
    letter-spacing: -0.01em;

    .icon-color {
      stroke: $sc_primary-color;
    }
  }
}
