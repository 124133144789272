.api-error {
  background-color: #34b78f;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.api-error__container {
  position: absolute;
  left: 0;
  top: 50%;
  padding: 0 20px;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
}

.api-error__logo {
  width: 140px;
  height: 100px;
  display: inline-block;
  margin-bottom: 25px;
}

.api-error__title {
  color: #fff;
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 35px;
  line-height: 1.2em;
}

.bg-cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  > * {
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    left: -9999px;
    top: -9999px;
    right: -9999px;
    bottom: -9999px;
    margin: auto;
  }
}

.error-container {
  background-color: #aacbd0;
  background-size: 100%;
  padding-top: 20px;
}

.back-to-platform-button {
  margin-top: 20px;
  display: flex;
  padding: 17px 69px;
  justify-content: center;
  align-items: center;
  border-radius: 27px;
  background: #3d8a9d;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}

.error-header {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0 20px;
  align-items: center;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    padding: 0;
    align-items: end;
  }

  h1 {
    color: #fff;
    font-size: 3.571em;
    max-width: 700px;
    line-height: 1.1;

    @media screen and (min-width: 768px) {
      width: 600px;
    }
  }

  &:before,
  &:after {
    content: "";
    background-repeat: no-repeat;
    display: block;
    background-size: contain;
    background-position: bottom;
  }

  &:before {
    background-image: url("../../../../../static/img/sporty-person.svg");
    width: 168px;
    height: 150px;
    margin-right: 10px;
    display: none;

    @media screen and (min-width: 1450px) {
      display: block;
    }
  }

  &:after {
    background-image: url("../../../../../static/img/sporty-group.svg");
    width: 300px;
    height: 95px;
    display: none;

    @media screen and (min-width: 768px) {
      width: 600px;
      height: 190px;
      display: block;
    }
  }
}
