.sc_team-info {
  display: flex;
  padding: 0.5rem 1rem;
  margin: 0 0 1.5rem;
  align-items: center;

  .sc_icon {
    svg {
      height: 1.2rem;
    }
  }

  p {
    font-size: 0.75rem;
    margin-bottom: 0;
    margin-left: 0.5rem;
  }
}

.sc_team-members {
  margin-bottom: 2.25rem;
}

.sc_team-members-single {
  border-bottom: 1px solid #e7e8e8;
  animation: memberIn 0.2s linear;
  animation-fill-mode: both;
  display: flex;
  padding: 0.75rem 0.5rem;

  .rank {
    flex: none;
    padding-right: 10px;
  }

  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .name + div {
    margin-left: auto;
  }

  .steps {
    text-align: right;
    padding-left: 10px;
  }

  .deactivated {
    font-size: 0.75em;
    text-transform: uppercase;
  }
}

.sc_team-members-single.own-member {
  background-color: #eaf7f3;
}

.sc_team-founder {
  text-transform: uppercase;
  color: #1c5d66;
  margin-left: 1em;
  font-size: 0.75em;
}

@keyframes memberIn {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.sc_team-members-header {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  text-transform: uppercase;

  .steps-count {
    text-align: right;
    font-size: 0.875rem;
    color: #596262;
  }
}

.sc_team-send-message-all {
  display: inline-block;
  border-left: 1px solid #e7e8e8;
  margin-left: 0.5em;
  padding-left: 0.5em;
  height: 24px;
  margin-right: auto;
  cursor: pointer;
  vertical-align: middle;
}

.sc_team-send-message-one svg {
  vertical-align: middle;
  cursor: pointer;
}

.sc_interaction-box {
  padding: 0.75rem 1.25rem;
  background-color: #fefbf0;
  border: 1px solid #b49a4f;
  border-radius: 8px;
  margin-bottom: 20px;

  &.warning {
    border-color: #931b48;
    background-color: #fceaf1;

    .sc_button {
      color: #931b48;
    }
  }

  p {
    font-size: 0.875rem;
  }

  .sc_button {
    color: #554925;
    margin: 0;
    font-size: 0.875rem;
  }
}

.sc_share {
  margin: 2rem 0 3em;
}

.sc_share-button {
  @extend .sc_button;
  @extend .sc_button-neutral;
  display: flex;
  align-items: center;
  font-weight: 400;
  transition: all 0.2s ease-in-out;
  margin: 1rem 0;
  padding: 12px 20px;
  width: 100%;

  svg {
    margin-right: 15px;
    height: 24px;
    width: 24px;
  }

  path {
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    color: $sc_primary-color;

    path {
      stroke: $sc_primary-color;
    }
  }

  &.success {
    color: $sc_text-color;
    cursor: default;

    &:hover {
      path {
        stroke: transparent;
      }
    }
  }

  &.error {
    color: $sc_text-color;
    cursor: default;

    svg {
      height: 24px;
      padding: 5px;

      polygon {
        fill: $sc_raspberry;
      }
    }
  }
}
