.product-detail {
  .appointment_tag {
    padding: 4px 8px;
    align-items: center;
    gap: 10px;
    border-radius: var(--radius-full, 9999px);
    border: 1px solid var(--Colors-Border-border-secondary, #ebebeb);
    background: var(--Colors-Background-bg-secondary, #fafafa);
    color: #122020;
    white-space: nowrap;
    /* H4 Sub 14 */
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    height: fit-content;
    font-weight: normal;
  }

  .appointment_tag--green {
    color: var(--Colors-Text-text-brand-secondary, #2a9272);
    border-color: var(--Colors-Border-border-brand_subtle, #a9e1d0);
    background-color: var(--Colors-Background-bg-brand-primary, #ecf9f4);
  }

  .appointment_tag--warning {
    color: var(--Colors-Text-text-error-primary, #ca382e);
    border-color: var(--Colors-Border-border-error_subtle, #f8afaa);
    background-color: var(--Colors-Background-bg-error-primary, #feedec);
  }

  display: flex;
  gap: var(--spacing-spacing-md, 24px);
  @include media(">md") {
    gap: var(--spacing-spacing-lg, 32px);
  }
  margin-bottom: 48px;
  flex-direction: column;

  @include media(">lg") {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 464px);
    justify-content: space-between;

    > div,
    > h2,
    section {
      grid-column-end: 2;
    }

    .product-detail__product-price-box-card-wrapper {
      grid-column-start: 2;
      align-self: start;
      grid-row: 1 / 9;
      position: sticky;
      top: 74px;
    }
  }

  @include media(">md") {
    padding: 0 24px;
  }

  h1 {
    margin: 0;
  }

  h2 {
    color: #717979;
    font-size: 22px;
    font-weight: 400;
    line-height: 30px; /* 136.364% */
  }

  @include media("<lg") {
    width: 100%;

    h2 {
      font-size: 18px;
      line-height: 28px;
    }
  }

  section {
    display: flex;
    padding: 0px;
    gap: 8px;

    @include media("<md") {
      flex-direction: column;
    }

    h5 {
      width: 200px;
      color: #000;
      font-size: 18px;
      font-weight: 700;
      line-height: 28px; /* 155.556% */

      @include media("<md") {
        font-size: 16px;
        line-height: 24px; /* 150% */
      }
    }

    p {
      margin: 0;
    }

    div {
      p {
        margin-bottom: 16px;
      }
    }

    div,
    p {
      font-size: 16px;
    }
  }

  .required-user-infos {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm, 6px);

    div {
      order: unset;
      width: 100%;
    }

    label {
      color: var(--Colors-Text-text-secondary, #4d4d4d);
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }

    input {
      height: 52px;
      padding: 12px 16px;
      border-radius: 26px;
      border: 1px solid #868686;
      border-radius: var(--radius-full, 9999px);
      background: var(--Colors-Background-bg-primary, #fff);
    }
  }
}

.product-detail__media-container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include media(">md") {
    flex-direction: row;
    max-height: 482px;
  }

  .product-detail__thumbnail {
    border-radius: 4px;
    width: 100%;
    object-fit: contain;
    border: 1px solid #ebebeb;

    @include media(">md") {
      width: 80%;
      order: 2;
    }
  }

  .product-detail__media-selector {
    display: flex;
    gap: 8px;
    width: 100%;
    align-items: flex-start;
    margin-bottom: 24px;

    @include media(">md") {
      flex-direction: column;
      width: 20%;
      height: 100%;
      margin: 0;
    }

    .product-detail__media {
      height: 54px;
      cursor: pointer;
      width: 84.25px;
      max-width: 25%;
      opacity: 60%;
      border: 1px solid #ebebeb;
      border-radius: var(--radius-radius-minimal, 4px);
      object-fit: contain;

      @include media(">md") {
        flex-direction: column;
        max-width: 100%;
        width: 100%;
        height: 114px;
      }

      iframe {
        pointer-events: none;
        border-radius: var(--radius-radius-minimal, 4px);
        width: 100%;

        @include media(">md") {
          flex-direction: column;
          height: 114px;
        }
      }
    }

    .active {
      transition: all 0.2s ease-in-out;
      opacity: 100%;
    }
  }
}

.product-detail__title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  > div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 8px;
    @include media(">md") {
      flex-direction: row;
    }
  }

  h1 {
    color: #122020;
    font-size: 36px;
    font-weight: 400;
    line-height: 40px; /* 111.111% */

    @include media("<md") {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .tags {
    display: flex;
    gap: 8px;
    .tag {
      padding: 4px 8px;
      align-items: center;
      gap: 10px;
      border-radius: var(--radius-full, 9999px);
      border: 1px solid var(--Colors-Border-border-secondary, #ebebeb);
      background: var(--Colors-Background-bg-secondary, #fafafa);
      color: #122020;
      white-space: nowrap;
      /* H4 Sub 14 */
      font-size: 14px;
      line-height: 20px; /* 142.857% */
      height: fit-content;
    }
    .tag--preventive {
      color: var(--Colors-Text-text-brand-secondary, #2a9272);
      border-color: var(--Colors-Border-border-brand_subtle, #a9e1d0);
      background-color: var(--Colors-Background-bg-brand-primary, #ecf9f4);
    }
  }
}

.product-detail__schedule {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-md, 16px);

  a {
    color: var(--Component-Colors-Buttons-Tertiary-button-tertiary-fg, #4d4d4d);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px; /* 150% */
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-sm, 6px);
  }
}

.product-detail__checkout-box {
  width: 35%;
}

.product-detail__details {
  display: flex;
  flex-direction: column;
  gap: 48px;

  @include media("<md") {
    gap: 24px;
  }
}

.product-detail__description {
  display: flex;
  margin-left: 42px;
  width: 100%;
  flex-direction: column;

  a {
    text-decoration: underline;
  }

  @include media("<md") {
    margin-left: 0;
  }

  .description-text {
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow-y: hidden;
    mask-image: linear-gradient(to bottom, #fff 60%, transparent 100%);

    &.open {
      max-height: unset;
      mask-image: none;
    }
  }
}

.product-detail__key-facts {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px var(--spacing-spacing-md, 24px);
  flex: 1 0 0;
  flex-wrap: wrap;

  span {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

    &:before {
      content: "";
      background-repeat: no-repeat;
      display: block;
      background-image: url("../../../images/tick.svg");
      width: 16px;
      height: 16px;
    }
  }

  @include media("<md") {
    margin-left: 0;
  }
}

.product-detail__appointment-selector {
  margin-left: 0;
  width: 100%;
  z-index: unset;

  .sort-by_button {
    font-size: 16px;
    border-color: #c7c7c7;
    color: #808080;

    &:hover  {
      border-color: #c7c7c7;
    }
  }

  .appointment-item {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .popup {
    max-height: 400px;
    overflow-y: auto;
    z-index: 1;
    height: unset;
    bottom: unset;
  }

  @include media("<md") {
    width: 100%;
    background-position: unset;

    .sort-by_button {
      background-image: url("../../../images/down_icon.svg");
      background-position: right 10px center;

      &.open {
        background-image: url("../../../images/up_icon.svg");
      }
    }

    .popup {
      position: absolute;
      z-index: 20000;
      top: calc(100% + 10px);
    }
  }
}

.product-detail__booking-warning {
  display: flex;
  gap: 8px;
}

.product-detail__course-dates {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 42px;

  span {
    padding: 8px 16px;
    border-bottom: 1px solid #cedee7;
    color: #122020;
    font-size: 16px;
    line-height: 24px; /* 150% */
  }

  @include media("<md") {
    margin-left: 0;
  }
}

.product-detail__attachments {
  display: flex;
  flex-direction: column;
  text-decoration: underline;
  align-items: flex-start;
  gap: 16px;
}

.product-detail__supplier-details {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;

  & > * {
    display: flex;
    flex-direction: column;
    gap: 8px;

    a {
      word-break: break-all;
    }

    div {
      display: flex;
      gap: 16px;
      cursor: pointer;
    }
  }

  .product-detail__supplier-header {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px; /* 125% */
  }
}

.product-detail__location-text {
  width: 234px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  h6 {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    margin-bottom: 0;
  }

  @include media("<md") {
    width: 100%;
  }
}

.product-detail-favorite {
  cursor: pointer;
  display: flex;
  padding: 14px;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md, 8px);
  border-radius: var(--radius-full, 9999px);
  border: 1px solid var(--Component-Colors-Buttons-Secondary-button-secondary-border, #c7c7c7);
  background: var(--Component-Colors-Buttons-Secondary-button-secondary-bg, #fff);

  img {
    transition: all 0.1s ease-in-out;
    opacity: 0.3;
  }

  &:hover {
    img {
      opacity: 1;
    }
  }

  &.active {
    animation-name: pulse;
    animation-duration: 0.3s;

    img {
      opacity: 1;
    }
  }
}

.product-detail__faqs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-spacing-md, 24px);
  flex: 1 0 0;

  .product-detail__faq {
    display: flex;
    padding-bottom: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-spacing-md, 24px);
    align-self: stretch;
    border-bottom: 1px solid #cedee7;

    .faq-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      cursor: pointer;

      span {
        color: var(--Dark-Night-Black-100, #122020);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
    }

    .faq-toggle {
      width: 16px;
      height: 16px;
      background-image: url("../../../images/down_icon.svg");
      background-position: center;
      background-repeat: no-repeat;

      &.open {
        transform: rotate(180deg);
      }
    }

    .faq-text {
      display: none;
      align-self: stretch;
      flex-direction: column;

      color: var(--Dark-Night-Black-100, #122020);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      &.open {
        display: flex;
      }
    }
  }
}
