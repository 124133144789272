.print-page {
  display: block;
}

.content-sidebar {
  .no-decoration {
    margin-bottom: 20px;
    display: inline-block;
  }
  img {
    width: 100%;
  }
}

.container-xl {
  @media screen and (min-width: 1200px) {
    width: 100%;
    max-width: 1530px;
  }
}

.mf-static-page .nav-tabs > li > a {
  @media screen and (max-width: 400px) {
    padding: 5px;
  }
}

@media print {
  .content-page {
    font:
      12pt Georgia,
      "Times New Roman",
      Times,
      serif;
    line-height: 1.3;
    page-break-after: always;

    .pace,
    .nav,
    .alert,
    .print-page,
    .content-sidebar,
    a[href]:after {
      content: none !important;
    }

    img {
      max-width: 100%;
      border: 0;
    }

    .mf-l_container-content {
      padding: 0 !important;
    }
  }
}

.content-search-item {
  @media screen and (min-width: 992px) {
    float: right !important;
  }
}

.content-search-form {
  display: flex;
  margin: 3px 15px 20px;

  input {
    flex-grow: 2;
    border-radius: 3px 0 0 3px;
    border: 1px solid #ddd;
    border-right: 0;
    padding: 7px 10px;

    &::placeholder {
      color: #ccc;
    }
  }

  input:focus {
    outline: none;
  }

  button {
    border: 0;
    border-radius: 0 3px 3px 0;
    padding: 0;
    background-color: var(--base-color);
    color: white;
  }

  button i {
    width: 35px;
    height: 25px;
    line-height: 25px;
  }
}
