.grid-item {
  padding: 15px 11px;
  position: relative;
  display: flex;

  @include media("<lg") {
    padding: 7px;
  }
}

.grid-product {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  @include media("<sm") {
    flex-basis: 100%;
    max-width: 100%;
  }
  @include media("<lg" and ">=sm") {
    flex-basis: 50%;
    max-width: 50%;
  }
  @include media("<xl" and ">=lg") {
    flex-basis: 33.33%;
    max-width: 33.33%;
  }
  @include media(">=xl") {
    flex-basis: 25%;
    max-width: 25%;
  }
}

.grid-product-startpage {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  @include media("<xs") {
    flex-basis: 100%;
    max-width: 100%;
  }
  @include media("<lg" and ">=xs") {
    flex-basis: 50%;
    max-width: 50%;
  }
  @include media("<xl" and ">=lg") {
    flex-basis: 33.33%;
    max-width: 33.33%;
  }
  @include media(">=xl") {
    flex-basis: 25%;
    max-width: 25%;
  }
}

.grid-featured-article {
  flex-basis: 100%;
  width: 100%;
}

.grid-articles-list {
  display: flex;
  flex-wrap: wrap;

  li,
  div {
    max-width: 100%;
    flex-basis: 100%;
    @include media(">=lg") {
      flex-basis: 50%;
    }
  }
}

.special-link {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 12px;
  @apply flex;
  @apply justify-center;
  @apply items-center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
  outline: none;

  svg {
    transition: all 0.1s ease-in-out;
    opacity: 0.2;

    .heart {
      fill: transparent;
    }
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }

  &.active {
    animation-name: pulse;
    animation-duration: 0.3s;

    svg {
      opacity: 1;

      .heart {
        fill: #fd5656;
      }
    }
  }
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.4, 1.4, 1.4);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.single-label {
  margin-right: 6px;
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  padding: 5px 15px;
  margin-bottom: 8px;
  border-radius: 15px;
  @include media("<lg") {
    font-size: 10px;
  }
}

.text-overlap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  z-index: 6;
  &-hover {
    display: none;
    position: absolute;
    z-index: 10;
    top: 2px;
    left: -3px;
    right: 0;
    padding: 0 5px 0 3px;
    background: #fff;
    @include media("<xl") {
      left: 17px;
    }
  }
}

.read-more {
  font-size: 15px;
  line-height: 26px;
  position: absolute;
  left: 20px;
  bottom: 20px;
}

.content-loader {
  overflow-x: hidden;
  &:before {
    content: "";
    position: absolute;
    left: 30px;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0),
      hsla(0, 0%, 100%, 0.3) 47%,
      hsla(0, 0%, 100%, 0.3) 52%,
      hsla(0, 0%, 100%, 0.3) 70%,
      hsla(0, 0%, 100%, 0)
    );
    animation-name: content-loader-animation;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
  }
}

@keyframes content-loader-animation {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}
